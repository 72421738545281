import React, { useContext, memo } from 'react';

import TableContext from './TableContext';

function THead() {
  const { getColumns } = useContext(TableContext);
  return (
    <thead>
      <tr>
        {getColumns().map((col, i) => {
          const key = `th-${i}`;
          return <th key={key}>{col.title || ''}</th>;
        })}
      </tr>
    </thead>
  );
}

THead.defaultProps = {};
THead.propTypes = {};

export default memo(THead);
