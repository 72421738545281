import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addApp } from '~/store/actions/app';

export function useReducerApp() {
  const stateApp = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const dispatchApp = useCallback(
    (payload) => {
      dispatch(addApp(payload));
    },
    [dispatch],
  );

  return [stateApp, dispatchApp];
}
