import styled from 'styled-components';

export const PrintReportContainer = styled.div`
  display: block;
  color: #000;
  border: 0;
  min-height: 100%;
  max-width: 100%;
  font-size: 12pt;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5cm;
  @media print {
    background-color: transparent;
  }
`;

export const Page = styled.div`
  display: block;
  background-color: white !important;
  width: 21cm;
  min-height: 29.7cm;
  padding: 0.5cm;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  border-radius: 0;
  background: white;
  box-shadow: 5px 5px 8px rgba(102, 102, 102, 1);
  h1 {
    font-size: 14pt;
  }

  @media print {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    margin: 0;
    /* width: 21cm;
    min-height: 29.7cm; */
    padding: 0.5cm;
    /* margin: 0;
    border-radius: 0;
    border: 0; */
    background: initial;

    box-shadow: initial;
    page-break-after: always;
  }
`;
