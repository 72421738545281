/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';

import './new-menu-lateral.css';

import NewMenuLateralContext from './MenulateralContext';

export default function Provider({ children }) {
  const [open, setOpen] = useState(false);

  const setOpenContext = useCallback((sinal) => {
    setOpen(sinal);
  }, []);

  const isOpen = useCallback(() => !!open, [open]);

  const setToggleContext = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <NewMenuLateralContext.Provider value={{ isOpen, setOpenContext, setToggleContext }}>
      {children}
    </NewMenuLateralContext.Provider>
  );
}
