import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// import styled from 'styled-components';

import './snakke.css';

export default function Snakke(props) {
  const { progressColor, className, progressClassName, fixed } = props;
  const [progress, setProgress] = useState(0);
  const colorir = progressColor ? { backgroundColor: progressColor } : {};

  const barStyle = {
    width: `${progress}%`,
    ...colorir,
  };

  function handleScroll() {
    const body = document.body || document.querySelectorAll('body')[0];
    const html = document.documentElement;

    const bodyClientHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );

    const bodyHeight = bodyClientHeight - document.documentElement.clientHeight;
    const p = (window.scrollY / bodyHeight) * 100;
    // console.log('scroll', p);
    setProgress(p);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={cx('snakke-main', className, { fixed: !!fixed })}>
      <div className={cx('snakke-progress', progressClassName)} style={barStyle} />
    </div>
  );
}

Snakke.propTypes = {
  progressColor: PropTypes.string,
  className: PropTypes.string,
  progressClassName: PropTypes.string,
  fixed: PropTypes.bool,
};

Snakke.defaultProps = {
  progressColor: null,
  className: null,
  progressClassName: null,
  fixed: false,
};
