import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DivHeader = styled.div`
  display: block;
  border-bottom: 2px solid #000;
`;

const Title = styled.h1`
  display: block;
  text-align: center;
  padding: 0;
  margin: 0 auto;
`;

export default function Header(props) {
  const { title } = props;
  return (
    <>
      <DivHeader>
        <Title>{title}</Title>
      </DivHeader>
    </>
  );
}

Header.defaultProps = {
  title: `Sem t\u00edtulo`,
};
Header.propTypes = {
  title: PropTypes.string,
};
