import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ToolSelectItem } from '~/components/ToolsBar';

import { useChartsOptions } from './ChartsContext';

export default function ToolBarSelectGuarita({ onChange, name }) {
  const { guaritaOptions } = useChartsOptions();

  const optionList = useMemo(() => {
    return [{ id: 0, title: 'Todos' }, ...guaritaOptions];
  }, [guaritaOptions]);

  return (
    <>
      {guaritaOptions && guaritaOptions.length ? (
        <ToolSelectItem
          defaultOptions={optionList}
          name={name}
          defaultValue={0}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}
ToolBarSelectGuarita.defaultProps = {
  onChange: () => {},
  name: 'nonamed',
};
ToolBarSelectGuarita.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};
