import React from 'react';
import PropTypes from 'prop-types';
import { ToolSelectItem } from '~/components/ToolsBar';

import { useChartsOptions } from './ChartsContext';

export default function ToolBarSelectDays({ onChange, defaultValue }) {
  const { daysOptions } = useChartsOptions();

  return (
    <>
      {daysOptions && daysOptions.length ? (
        <ToolSelectItem
          defaultOptions={daysOptions}
          name="lastDays"
          defaultValue={defaultValue}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}

ToolBarSelectDays.defaultProps = {
  onChange: () => {},
  defaultValue: 30,
};
ToolBarSelectDays.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
