import { useState, useEffect, useMemo, useCallback } from 'react';
import io from 'socket.io-client';

import { apiURL } from '~/config';
import { useReducerAuth } from '~/store/hooks/auth';

function configureSocket(socketIo) {
  socketIo.onAny((_event, payload) => {
    if (payload && payload.messageId) {
      socketIo.emit(`received-${payload.messageId}`, payload.messageId);
      // eslint-disable-next-line no-console
      console.log('configureSocket', _event);
    }
  });
}

export function useSocket() {
  const [socket, setSocket] = useState(null);
  const [{ token }] = useReducerAuth();

  useEffect(() => {
    const socketIo =
      token &&
      io(apiURL, {
        extraHeaders: {
          Authorization: token,
        },
      });
    if (socketIo) {
      configureSocket(socketIo);
      setSocket(socketIo);
    }
    return () => {
      if (socketIo) socketIo.disconnect(true);
    };
  }, [token]);

  return socket;
}

export function useSocketLpr(lprId) {
  const [socket, setSocket] = useState(null);

  const [{ token }] = useReducerAuth();

  const query = useMemo(() => {
    return {
      lpr: lprId,
      type: 'lpr',
    };
  }, [lprId]);

  useEffect(() => {
    const socketIo =
      token &&
      io(apiURL, {
        query,
        extraHeaders: {
          Authorization: token,
        },
      });
    if (socketIo) {
      configureSocket(socketIo);

      setSocket(socketIo);
    }
    return () => {
      if (socketIo) socketIo.disconnect(true);
    };
  }, [query, token]);

  return socket;
}

/**
 * @typedef {Object} UseSocketUser
 * @property {import('socket.io-client').Socket} socket
 * @property {any[]} notifyList
 */
/** @returns {UseSocketUser} */
export function useSocketUser() {
  const [socket, setSocket] = useState(null);
  const [notifyList, setNotifyList] = useState([]);
  const [{ token }] = useReducerAuth();

  const updateNotify = useCallback((list) => {
    setNotifyList(list);
  }, []);

  useEffect(() => {
    const socketIo =
      token &&
      io(apiURL, {
        query: { type: 'user' },
        extraHeaders: {
          Authorization: token,
        },
      });
    if (socketIo) {
      configureSocket(socketIo);
      socketIo.on('notify', (list) => updateNotify(list));
      setSocket(socketIo);
    }

    return () => {
      if (socketIo) socketIo.disconnect(true);
    };
  }, [token, updateNotify]);

  return { socket, notifyList };
}
