export const daysOptions = [
  { id: 10, title: '10 dias' },
  { id: 30, title: '30 dias' },
  { id: 60, title: '60 dias' },
  { id: 90, title: '90 dias' },
  { id: 120, title: '120 dias' },
];

export const hoursOptions = [
  { id: 6, title: '6 horas' },
  { id: 12, title: '12 horas' },
  { id: 24, title: '24 horas' },
  { id: 48, title: '48 horas' },
  { id: 72, title: '72 horas' },
  { id: 96, title: '4 dias' },
];
