/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Grupo(props) {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames('grupo', className)} {...rest}>
      {children}
    </div>
  );
}

Grupo.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Grupo.defaultProps = {
  children: null,
  className: null,
};
