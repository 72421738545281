import styled, { css } from 'styled-components';

export const LandMenuLateral = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0 0;
  max-width: 100%;
  border: 0;
  z-index: 2998;
  ${({ right }) =>
    right
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
`;

export const MainMenuLateral = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0 0;
  max-width: 100%;
  width: ${({ width }) => `${width || 240}px`};
  border: 0;
  transition: all ease 0.3s;
  position: absolute;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  z-index: 2999;
  ${({ right }) =>
    right
      ? css`
          right: 0;
          box-shadow: rgba(0, 0, 0, 0.5) -1px -1px 3px;
        `
      : css`
          left: 0;
          box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 3px;
        `}
`;
