import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useReducerAuth } from '~/store/hooks/auth';

import LinkLi from './Links';
import ButtonOut from '../../ButtonOut';

export default function LinksMenu(props) {
  const { sindicoRouteList, adminRouteList, devRoutesList } = props;
  const [redirect, setRedirect] = useState(false);
  const [{ user }] = useReducerAuth();
  const nivel = user ? user.nivel || 0 : 0;

  function handleOut() {
    setRedirect(true);
  }

  if (redirect) return <Redirect to="/logout" />;

  return (
    <>
      <div className="new-menu-links">
        {nivel >= 9 ? (
          <>
            <h3>Desenvolvedor</h3>
            <ul className="dev-menu">
              {devRoutesList.map((l, i) => {
                if (l.noMenu) return null;
                if (l.minLevel && nivel < l.minLevel) return null;

                const key = `dev-links-${nivel}-${i}`;
                return (
                  <LinkLi key={key} label={l.name} to={l.path} icon={l.icon} activeOnlyWhenExact />
                );
              })}
            </ul>
          </>
        ) : null}
        {nivel >= 7 ? (
          <>
            <h3>Administrador</h3>
            <ul className="admin-menu">
              {adminRouteList.map((l, i) => {
                if (l.noMenu) return null;
                if (l.minLevel && nivel < l.minLevel) return null;

                const key = `admin-links-${nivel}-${i}`;

                return (
                  <LinkLi key={key} label={l.name} to={l.path} icon={l.icon} activeOnlyWhenExact />
                );
              })}
            </ul>
          </>
        ) : null}
        {nivel >= 6 ? (
          <>
            <h3>{`S\u00edndico`}</h3>
            <ul className="sindico-menu">
              {sindicoRouteList.map((l, i) => {
                if (l.noMenu) return null;
                const key = `sindico-links-${nivel}-${i}`;
                return (
                  <LinkLi key={key} label={l.name} to={l.path} icon={l.icon} activeOnlyWhenExact />
                );
              })}
            </ul>
          </>
        ) : null}

        <ButtonOut onClick={handleOut} />
      </div>
    </>
  );
}

LinksMenu.propTypes = {
  sindicoRouteList: PropTypes.array,
  adminRouteList: PropTypes.array,
  devRoutesList: PropTypes.array,
};
LinksMenu.defaultProps = {
  sindicoRouteList: [],
  adminRouteList: [],
  devRoutesList: [],
};
