import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grupo, ButtonCampo, CampoContent, Campo, CampoMask } from 'avatardomus-components';
import { gotoColumn } from 'rollcolumn-react';

export default function Step1({ emailDisabled, isHidden }) {
  function handleNext() {
    gotoColumn('rc-cadastro', 2, true);
  }

  const buildStyle = useCallback(() => {
    return {
      display: `${isHidden ? 'none' : 'block'}`,
      opacity: `${isHidden ? 0 : 1}`,
      animation: 'all 0.8s linear',
    };
  }, [isHidden]);

  return (
    <div style={buildStyle()}>
      <p className="c">Registre-se para ter acesso ao sistema</p>
      <Campo name="email" lbicon="email" placeholder="seu e-mail" disabled={emailDisabled} />
      <Campo name="nome" lbicon="name" placeholder="seu nome" />
      <CampoMask
        name="cel"
        mask="(99) 99999-9999"
        className="primary"
        lbicon="cel"
        placeholder="celular/whatsapp"
      />
      <hr />
      <Grupo fixed>
        <CampoContent wchild="w50" />
        <ButtonCampo wchild="w50" type="button" model="text" iconR="next" onClick={handleNext}>
          {`PR\u00d3XIMA`}
        </ButtonCampo>
      </Grupo>
    </div>
  );
}

Step1.defaultProps = {
  emailDisabled: false,
  isHidden: false,
};
Step1.propTypes = {
  emailDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
};
