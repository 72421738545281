import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { themeActions } from '~/store/actions/constants';
import { failureTheme, successTheme } from '~/store/actions/theme';
import { publicUrl } from '~/config';

import api from '~/services/Api/ApiAuthless';

export function* getTheme() {
  try {
    const newTheme = yield call(api.post, '/theme', {
      publicUrl,
      locationRef: window.location.href,
    });
    const { data } = newTheme;

    if (!data) return yield put(failureTheme());
    if (!data.app || !data.app.theme) return yield put(failureTheme());
    const name = data.app.name || 'no named';

    const atual = yield select((state) => state.theme.data);
    yield put(successTheme({ data: { ...atual, name, ...data.app.theme } }));

    return true;
  } catch (error) {
    return yield put(failureTheme());
  }
}

export default all([takeLatest(themeActions.requestTheme, getTheme)]);
