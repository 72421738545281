export const panelName = 'painel-lpr';

export const defaultValues = {
  actived: true,
  label: '',
  serialno: '',
};

export function prepareFormData(formData) {
  const { lprid } = formData;
  return {
    ...formData,
    lprid: parseInt(lprid, 10) || 0,
  };
}
