import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as authActions from '~/store/actions/auth';

function Logout(props) {
  const { auth, requestLogout, failureLogin } = props;

  const saia = useCallback(() => {
    if (!auth.token) return false;
    requestLogout({ token: auth.token });
    return true;
  }, [auth.token, requestLogout]);

  useEffect(() => {
    saia();
    return () => {
      failureLogin();
    };
  }, [failureLogin, saia]);

  return <Redirect to="/" />;
}

Logout.propTypes = {
  auth: PropTypes.object,
  failureLogin: PropTypes.func,
  requestLogout: PropTypes.func,
};

Logout.defaultProps = {
  auth: {},
  failureLogin: () => {},
  requestLogout: () => {},
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...authActions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
