/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import Snakke from '~/components/Snakke';
import { Header } from '~/components/_layout';
import {
  ProviderMenu,
  MenuLateral,
  LinksMenu,
  IconMenu,
} from '~/components/_layout/NewMenuLateral';
import Notify from '../Notify';

import { useReducerTheme } from '~/store/hooks/theme';
import { sindicoRouteList, adminRouteList, devRoutesList } from '~/routes/private';

export default function SindicoLayout(props) {
  const { children } = props;
  const [{ data: theme }] = useReducerTheme();

  return (
    <ProviderMenu>
      <MenuLateral width={240} theme={theme}>
        <LinksMenu
          sindicoRouteList={sindicoRouteList}
          adminRouteList={adminRouteList}
          devRoutesList={devRoutesList}
        />
      </MenuLateral>
      <Snakke progressColor={theme.contrast} />
      <Header>
        <div className="header-tools">
          <div className="header-item-menu">
            <IconMenu />
          </div>
          <div>
            <Notify />
          </div>
        </div>
      </Header>
      {children}
    </ProviderMenu>
  );
}

SindicoLayout.propTypes = {
  children: PropTypes.any,
};
SindicoLayout.defaultProps = {
  children: null,
};
