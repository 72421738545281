import produce from 'immer';
import { authActions } from '~/store/actions/constants';
import config from '~/config';

const defaultAuth = {
  version: config.appVersion,
  token: null,
  signed: false,
  expires: null,
  loading: false,
  error: false,
  user: null,
  passport: null,
};

export default function authReducer(state = defaultAuth, action) {
  return produce(state, (draft) => {
    const { payload, type } = action;
    switch (type) {
      case authActions.requestLogin:
        // draft.token = null;
        draft.signed = false;
        draft.loading = true;
        draft.error = false;
        draft.passport = payload.passport;
        // draft.user = null;
        break;
      case authActions.successLogin:
        draft.token = payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.error = false;
        draft.user = payload.user || payload.data || {};
        draft.expires = payload.expires || null;
        break;
      case authActions.failureLogin:
        // draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.error = true;
        // draft.user = {};
        break;
      case authActions.requestLogout:
        draft.token = null;
        draft.signed = false;
        draft.loading = true;
        draft.error = false;
        draft.user = {};
        break;
      case authActions.successLogout:
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.error = false;
        draft.user = {};
        break;
      case authActions.manualStorage:
        draft.token = payload.token;
        draft.signed = payload.signed;
        draft.user = payload.user || payload.data || {};
        draft.expires = payload.expires || null;
        draft.error = false;
        draft.loading = false;
        break;
      default:
    }
  });
}
