import React, { memo } from 'react';
import PropTypes from 'prop-types';

function CellLabel({ original: row }) {
  return <>{row.label || row.nome}</>;
}
CellLabel.propTypes = { original: PropTypes.any };
CellLabel.defaultProps = { original: null };

export default memo(CellLabel);
