import produce from 'immer';
import { managerActions } from '~/store/actions/constants';

const defaultState = {
  id: 0,
  name: '--',
  loading: false,
};

export default function managerReducer(state = defaultState, action) {
  return produce(state, (draft) => {
    const { payload } = action;
    switch (action.type) {
      case managerActions.changeManager:
        draft.id = payload.id;
        draft.name = payload.name;
        draft.loading = payload.loading;
        break;
      default:
    }
  });
}
