/* eslint-disable react/prop-types */
import React from 'react';

import { defaultPayload } from '~/components/TableDef/defaultOptions';
import { RadioTable } from '~/components/TableDef';
import { CellToogle } from './Cells';

export const defaultOptions = {
  ...defaultPayload,
};

export const tableName = 'tabela-lprs';

export const columns = [
  {
    Header: '',
    width: 32,
    className: 'td-ctrl-linha',
    Cell: (props) => {
      const { row } = props;
      return <RadioTable {...props} name={tableName} value={row.id || 0} />;
    },
    sortable: false,
    resizable: false,
  },
  {
    Header: '#',
    width: 64,
    accessor: 'id', // String-based value accessors!
    className: 'c',
    headerClassName: 'c',
    sortable: true,
    resizable: false,
  },
  {
    Header: '',
    width: 64,
    accessor: 'actived', // String-based value accessors!
    className: 'c',
    headerClassName: 'c',
    sortable: false,
    resizable: false,
    Cell: ({ row }) => <CellToogle row={row} tableName={tableName} />,
  },
  {
    Header: 'NOME',
    accessor: 'label',
    className: 'l',
    headerClassName: 'l',
    sortable: true,
    resizable: false,
    // Cell: CellLabel,
  },
  {
    Header: 'SERIAL',
    accessor: 'serialno',
    className: 'l',
    headerClassName: 'l',
    sortable: true,
    resizable: false,
    // Cell: CellLabel,
  },
];
