import { imgIcon } from 'avatardomus-components';
import AdminDashboardLayout from '~/pages/_layouts/AdminDashboard';
// import Dashboard from '~/pages/Dashboard/Home';
import { LazyDashboardHome as Dashboard } from '~/routes/lazy/dashboard';

import adminSubrouteList from '~/routes/private/adminSubrouteList';
import { prepareRouteList } from '~/routes/routeHelpers';

const adminRouteList = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: imgIcon.requireList.dashboard,
    component: Dashboard,
    layout: AdminDashboardLayout,
    exact: true,
    subroute: adminSubrouteList,
    minLevel: 7,
  },
];

export default prepareRouteList(adminRouteList, true);
