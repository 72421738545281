import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import qs from 'query-string';
import { CircleLoading, ImageMessage } from 'avatardomus-components';

import { useIsMounted } from '~/services/customHooks';
import { useReducerTheme } from '~/store/hooks/theme';
import { ServerLogo } from '~/components/_layout/Images';
import { getKeyChecks } from '../makeData';

import FormReset from './FormReset';

export default function Resetpass() {
  const isMounted = useIsMounted();
  const [{ data: theme }] = useReducerTheme();
  const imgLogo = theme.assets ? theme.assets.logo || null : null;
  const { key, mode } = qs.parse(useLocation().search || {}) || {};
  const [authorization, setAuthorization] = useState(false);
  const [loading, setLoading] = useState(false);

  const requestAuthorization = useCallback(async () => {
    if (!authorization) {
      setLoading(true);
      const result = await getKeyChecks({ key });
      if (isMounted.current) {
        setLoading(false);
        if (result) {
          setAuthorization(result);
        } else {
          setAuthorization({ error: true });
        }
      }
    }
  }, [authorization, isMounted, key]);

  useEffect(() => {
    requestAuthorization();
  }, [requestAuthorization]);

  function renderMessage() {
    if (authorization && authorization.error) {
      return (
        <div>
          <br />
          <ImageMessage type="Building" text={`Houve um erro na solicita\u00e7\u00e3o`} />
          <p className="c">
            <Link to="/login">voltar para login</Link>
          </p>
        </div>
      );
    }

    if (authorization && !authorization.success) {
      return (
        <div>
          <br />
          <ImageMessage
            type="Security"
            text={authorization.message || `Este link expirou ou j\u00e1 foi utilizado`}
          />
          <p className="c">
            <Link to="/login">voltar para login</Link>
          </p>
        </div>
      );
    }
    return null;
  }

  const firstTime = !!mode === 'firsttime';

  return (
    <div className="fadein" style={{ width: 500, maxWidth: '100%' }}>
      <ServerLogo url={imgLogo} alt="LOGO" className="logo" />
      <br />
      {renderMessage()}
      {authorization && authorization.success ? (
        <FormReset authorize={authorization} firstTime={firstTime} />
      ) : null}
      {loading && (
        <CircleLoading>
          <p className="c">Aguarde...</p>
        </CircleLoading>
      )}
    </div>
  );
}
