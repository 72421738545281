import React from 'react';
import PropTypes from 'prop-types';

import Tr from './Tr';

export default function TBody({ data }) {
  return (
    <tbody>
      {data.map((row, i) => {
        const key = row && row.id ? `row-${i}-${row.id}` : `row-${i}-${Math.random()}`;
        return <Tr key={key} row={row} />;
      })}
    </tbody>
  );
}

TBody.defaultProps = {
  data: [],
};
TBody.propTypes = {
  data: PropTypes.array,
};
