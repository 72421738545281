import { onlineActions } from '~/store/actions/constants';

/**
 * @typedef {Object} onlineInRequest
 * @property {Boolean} loading
 * @property {String} url
 */

/**
 * @function onlineInRequest
 * @param {fecthPayload} payload
 */
export function onlineInRequest(payload) {
  return {
    type: onlineActions.inRequest,
    payload,
  };
}
