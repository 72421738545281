import React from 'react';
import { Tabs, Tab } from 'avatardomus-components';

import { useReducerTheme } from '~/store/hooks/theme';
import { ServerLogo } from '~/components/_layout/Images';

import FormEmail from './Email';
// import FormTelegram from './Telegram';

export default function Forgot() {
  const [{ data: theme }] = useReducerTheme();
  const imgLogo = theme.assets ? theme.assets.logo || null : null;

  return (
    <div className="fadein" style={{ width: 500, maxWidth: '100%' }}>
      <ServerLogo url={imgLogo} alt="LOGO" className="logo" />
      <p className="c">{`Escolha o m\u00e9todo de recupera\u00e7\u00e3o de senha`}</p>
      <br />
      <Tabs>
        <Tab label="Pelo e-mail" key={`tab${1}`}>
          <FormEmail />
        </Tab>
        {/* <Tab label="Pelo Telegram" key={`tab${2}`}>
          <FormTelegram />
        </Tab> */}
      </Tabs>
      <br />
    </div>
  );
}
