/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// COMPONENTS
import Grupo from '../Grupo';
import UnFormCheckToogle from './index';

export default function LineToogle(props) {
  const { children, className, label, name, ...rest } = props;
  const classGrupo = cx('fixed', 'between', 'line-toogle', className);
  return (
    <Grupo className={classGrupo}>
      <div className="line-toogle-text">
        {children || label}
        <label htmlFor={name} className="line-toogle-label" />
      </div>
      <div>
        <UnFormCheckToogle name={name} {...rest} />
      </div>
    </Grupo>
  );
}
LineToogle.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
  alerted: PropTypes.bool,
};
LineToogle.defaultProps = {
  className: null,
  label: null,
  children: null,
  alerted: false,
};
