import React, { useEffect, useCallback, useState } from 'react';
import { PageTitle, ListCards, CardItem, CardContent } from 'avatardomus-components';

import { useReducerIds } from '~/store/hooks/ids';
import { useSocketLpr } from '~/components/_layout/useSocketIo';
import PanelLPR from '~/pages/_common/LPR/PanelLPR';
import Plate from '~/pages/_common/LPR/Plate';

export default function DevPageLpr() {
  const [lpr] = useReducerIds('lpr');
  const socket = useSocketLpr(lpr);
  const [plate, setPlate] = useState({});

  const capturePlate = useCallback((plateData) => {
    if (plateData) setPlate(plateData);
    console.log('pegou placa');
  }, []);

  useEffect(() => {
    if (socket && lpr && lpr > 0) socket.on('plate', capturePlate);
    return () => {
      if (socket && (!lpr || (lpr && lpr <= 0))) socket.removeListener('plate', capturePlate);
    };
  }, [socket, lpr, capturePlate]);

  return (
    <>
      <PageTitle className="c" style={{ width: '100%', maxWidth: '100%' }}>
        <h1>Monitor LPR</h1>
      </PageTitle>
      <ListCards>
        <CardItem style={{ width: 800 }}>
          <CardContent>
            <PanelLPR />
          </CardContent>
        </CardItem>
        <CardItem style={{ width: 500 }}>
          <CardContent>
            <Plate plateData={plate} />
          </CardContent>
        </CardItem>
      </ListCards>
    </>
  );
}
