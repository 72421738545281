import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormContent, CampoHidden } from 'avatardomus-components';
import RollColumn, { Column } from 'rollcolumn-react';

// STEPS
import Step1 from './Step1';
import Step2 from './Step2';

export default function FormCadastro({ initialData, emailDisabled, onFormSubmit, loading }) {
  const [activedCol, setActivedCol] = useState(1);

  function handleColChange(e, col) {
    setActivedCol(col);
  }

  return (
    <FormContent initialData={initialData} onSubmit={onFormSubmit}>
      <CampoHidden name="key" />
      <RollColumn name="rc-cadastro" onColumnChange={handleColChange}>
        <Column order={1} checked>
          <Step1 emailDisabled={emailDisabled} isHidden={!(activedCol === 1)} />
        </Column>
        <Column order={2}>
          <Step2 loading={loading} clearPassword isHidden={!(activedCol === 2)} />
        </Column>
      </RollColumn>
    </FormContent>
  );
}

FormCadastro.defaultProps = {
  initialData: {},
  emailDisabled: false,
  loading: false,
  onFormSubmit: null,
};
FormCadastro.propTypes = {
  initialData: PropTypes.object,
  emailDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  onFormSubmit: PropTypes.func,
};
