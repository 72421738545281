import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { imgIcon } from 'avatardomus-components';

export const LabelButtonContent = styled.div`
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: flex-start;

  width: 800px;
  max-width: 100%;
  margin: 0 auto;
`;

const Item = styled.div`
  box-sizing: border-box;
  margin: 0;
  border: 0;
  max-width: 33.333%;
  width: 128px;
  margin-bottom: 6px;
  padding: 4px;
`;

const ItemContent = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.9) 2px 2px 3px;
  display: block;
  min-width: 72px;
  border-radius: 18px;
  a {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent !important;
  }
`;

const BgIcon = styled.div`
  display: block;
  margin: 0 auto;
  margin-top: 6px;
  min-height: 72px;
  width: 72px;
  max-width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  margin-bottom: 6px;
  background-image: url(${({ icon }) => icon});
`;

const Text = styled.div`
  color: inherit;
  text-align: center;
  font-size: 12px;
  flex-grow: 1;
  display: flex;
  margin: 0;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  border: 0;
  min-height: 32px;
`;

const PText = styled.p`
  display: block;
  font-size: 12px;
  padding: 0;
  margin: 0 auto;
`;

export function LabelIconsItem(props) {
  const { children, icon, label, link, disabled, ...rest } = props;

  const renderlabel = () => {
    return label ? <PText>{label}</PText> : children;
  };

  const renderLink = () => {
    return link && !disabled ? <Link to={link} className="labellink" /> : null;
  };

  return (
    <Item {...rest}>
      <ItemContent disabled={!!disabled}>
        <BgIcon icon={imgIcon.getImageByType(icon, true)} />
        <Text>{renderlabel()}</Text>
        {renderLink()}
      </ItemContent>
    </Item>
  );
}

LabelIconsItem.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
};
LabelIconsItem.defaultProps = {
  icon: 'unknow',
  children: null,
  className: null,
  label: null,
  link: null,
  disabled: false,
};
