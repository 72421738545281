/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// LOCAl IMPORTS
import './header.css';
import ImgLogo from './Logo';

export default function Header({ children, className }) {
  return (
    <div className={classNames('header', className)}>
      {children}
      <ImgLogo />
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Header.defaultProps = {
  children: null,
  className: null,
};

export { ImgLogo };
