import styled from 'styled-components';
import { ToolsBar } from 'avatardomus-components';

export const ChartContainer = styled.div`
  display: block;
  margin: 0 auto;
  padding: 0;
  height: 126px;
  box-sizing: border-box;
  border: 0;
`;

export const ToolsFooter = styled(ToolsBar)`
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 5px 0 0 0;
`;
