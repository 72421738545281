import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { columnsItemShape } from './shapes';
import TableContext from './TableContext';

import THead from './THead';
import TBody from './TBody';
import TFoot from './TFoot';

export default function Table(props) {
  const { columns, data } = props;

  const colKeys = useMemo(() => {
    return columns.map((col) => col.propData || false).filter((col) => !!col) || [];
  }, [columns]);

  const getColumns = useCallback(() => {
    return columns || [];
  }, [columns]);

  return (
    <TableContext.Provider value={{ colKeys, getColumns }}>
      <table className="printTable">
        <THead />
        <TBody data={data} />
        <TFoot />
      </table>
    </TableContext.Provider>
  );
}

Table.defaultProps = {
  columns: [],
  data: [],
};
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(columnsItemShape)),
  data: PropTypes.arrayOf(PropTypes.object),
};
