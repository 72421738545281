import produce from 'immer';
import { idActions } from '~/store/actions/constants';

export const initialStateIds = {
  morador: 0,
  guarita: 0,
  gestor: 0,
  alert: 0,
  company: 0,
  user: 0,
  module: 0,
  lpr: 0,
};

export default function idsReducer(state = initialStateIds, action) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case idActions.changeIds:
        if (typeof payload.morador !== 'undefined') draft.morador = payload.morador;
        if (typeof payload.guarita !== 'undefined') draft.guarita = payload.guarita;
        if (typeof payload.gestor !== 'undefined') draft.gestor = payload.gestor;
        if (typeof payload.alert !== 'undefined') draft.alert = payload.alert;
        if (typeof payload.company !== 'undefined') draft.company = payload.company;
        if (typeof payload.user !== 'undefined') draft.user = payload.user;
        if (typeof payload.module !== 'undefined') draft.module = payload.module;
        if (typeof payload.lpr !== 'undefined') draft.lpr = payload.lpr;
        break;
      default:
    }
  });
}
