import React from 'react';

import { PageTitle } from 'avatardomus-components';

import MenuIcons from './MenuIcons';
import Charts from './Charts';

export default function PageHome() {
  return (
    <>
      <PageTitle className="c">
        <h1 title={`\u00c1rea do s\u00edndico`}>{`\u00c1rea do s\u00edndico`}</h1>
      </PageTitle>
      <Charts />
      <MenuIcons />
    </>
  );
}
