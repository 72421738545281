export const themeActions = {
  requestTheme: 'REQUEST_THEME',
  successTheme: 'SUCCESS_THEME',
  failureTheme: 'FAILURE_THEME',
};

export const authActions = {
  requestLogin: 'REQUEST_AUTH',
  successLogin: 'SUCCESS_AUTH',
  failureLogin: 'FAILURE_AUTH',
  requestLogout: 'REQUESTOUT_AUTH',
  successLogout: 'SUCCESSOUT_AUTH',
  manualStorage: 'MANUAL_AUTH',
};

export const appActions = {
  addApp: 'ADD_APP',
  changePageTitle: 'CHANGEPAGETITLE_APP',
};

export const guaritaActions = {
  changeGuarita: 'CHANGE_GUARITA',
};

export const managerActions = {
  changeManager: 'CHANGE_MANAGER',
};

export const idActions = {
  changeIds: 'CHANGE_IDS',
};

export const onlineActions = {
  inRequest: 'INREQUEST_ONLINE',
};
