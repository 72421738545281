import React from 'react';
import { Link } from 'react-router-dom';
import { ImageMessage } from 'avatardomus-components';

export default function NoFound() {
  return (
    <>
      <br />
      <ImageMessage type="Sad">
        <p>
          P&aacute;gina n&atilde;o encontrada.
          <br />
          <Link to="/" className="c">
            Voltar
          </Link>
        </p>
      </ImageMessage>
    </>
  );
}
