import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestTheme, successTheme } from '~/store/actions/theme';

export function useReducerTheme() {
  const stateTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const dispatchTheme = useCallback(
    (payload) => {
      dispatch(requestTheme(payload));
    },
    [dispatch],
  );

  const setTheme = useCallback(
    (payload) => {
      dispatch(successTheme(payload));
    },
    [dispatch],
  );

  return [stateTheme, dispatchTheme, setTheme];
}

export function useReducerNewTheme() {
  const stateTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const setTheme = useCallback(
    (payload) => {
      dispatch(successTheme(payload));
    },
    [dispatch],
  );

  const dispatchTheme = useCallback(
    (payload) => {
      dispatch(requestTheme(payload));
    },
    [dispatch],
  );

  return { stateTheme, setTheme, requestTheme: dispatchTheme };
}
