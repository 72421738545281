import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useImage } from 'react-image';
import { apiURL } from '~/config';

import LocalLogo from './LocalLogo';
import logoFallBack from '~/assets/images/logo-fallback-nd.svg';

function LogoFallBack(props) {
  return <img src={logoFallBack} alt="logo" {...props} />;
}

function FecthLogo({ url, ...rest }) {
  const { src } = useImage({ srcList: [url, `${apiURL}/assets/logo/domus.svg`] });
  return <img src={src} alt="logo" {...rest} />;
}
FecthLogo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default function ServerLogo(props) {
  const { url, ...rest } = props;
  return (
    <>
      {url ? (
        <Suspense fallback={<LogoFallBack {...rest} />}>
          <FecthLogo url={url} {...rest} />
        </Suspense>
      ) : (
        <LocalLogo />
      )}
    </>
  );
}

ServerLogo.propTypes = {
  url: PropTypes.string.isRequired,
};
