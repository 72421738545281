import api from '~/services/Api';

/**
 * @function getKeyInviteChecks
 * @param {Object} payload
 */
export async function getKeyInviteChecks(payload) {
  const result = await api.post(`/invite/checks`, payload);
  if (!result || !result.data) return false;
  return result.data;
}

/**
 * @function sendUserInvite
 * @param {Object} payload
 */
export async function sendUserInvite(payload) {
  const result = await api.post(`/invite/store`, payload);
  if (!result || !result.data) return false;
  return result.data;
}
