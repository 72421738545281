export const panelName = 'painel-gestores';

export const defaultLevelOptions = [
  { id: 3, title: `Usu\u00e1rio` },
  { id: 6, title: `S\u00edndico` },
  { id: 7, title: 'Gestor' },
  { id: 8, title: 'Admin' },
  { id: 9, title: 'Dev' },
];

export const defaultValues = {
  active: true,
  ativo: false,
  bloqemail: false,
  bloqpass: false,
  cel: '',
  email: '',
  emok: false,
  label: '',
  nome: '',
  tel: null,
  nivel: 1,
  levelOptions: defaultLevelOptions,
};
