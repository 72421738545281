/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTable, { ReactTableDefaults } from '~/components/react-table-v6';
import { useIsMounted } from '~/services/customHooks';

// COMPONENTS
import TableContext from './TableContext';
import ButtonPagination from './ButtonPagination';

import { textOptions } from './defaultOptions';

import '~/components/react-table-v6/react-table.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './css/perfectscrollbar.css';
import './css/table-def.css';

const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function wrapper(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export default function TableDef(props) {
  const isMounted = useIsMounted();
  const psRef = useRef();
  const tbRef = useRef();
  const { name, onCheck, onChange, onToogleChange, onMountInputs, onMountToogles, width } = props;
  const [inputs, setInputs] = useState([]);
  const [toogles, setToogles] = useState([]);
  const [largura, setLargura] = useState('auto');
  const [padB, setPadB] = useState(false);

  const registerRadio = useCallback(
    (refInput) => {
      refInput.setAttribute('name', name);
      if (isMounted.current) {
        setInputs((oldInputs) => [
          ...oldInputs.filter((i) => i.ref.id !== refInput.id),
          { ref: refInput },
        ]);
      }
    },

    [name, isMounted],
  );

  const registerToogle = useCallback(
    (refToogle) => {
      const named = refToogle.getAttribute('name');
      if (!named) refToogle.setAttribute('name', `toogle-${name}`);
      if (isMounted.current) {
        setToogles((oldToogles) => [
          ...oldToogles.filter((i) => i.ref.id !== refToogle.id),
          { ref: refToogle },
        ]);
      }
    },
    [name, isMounted],
  );

  function radioCheck(e) {
    if (e.target) onCheck(e.target, inputs);
  }

  function radioChange(e) {
    if (e.target) onChange(e.target, inputs);
  }

  function toogleChange(e) {
    if (e.target) onToogleChange(e.target, toogles);
  }

  Object.assign(ReactTableDefaults, {
    ...textOptions,
    NextComponent: ButtonPagination,
    PreviousComponent: ButtonPagination,
  });

  function handleResize(detectW) {
    // eslint-disable-next-line no-underscore-dangle
    if (psRef.current) psRef.current._ps.update();

    if (isMounted.current) {
      if (width && detectW < width) {
        setLargura(width);
      } else if (width && detectW >= width) {
        setLargura(detectW);
      } else {
        setLargura('auto');
      }
      setPadB(!!(width && detectW < width));
    }
  }

  const handleSync = debounce((ps) => {
    if (isMounted.current) ps.update();
  }, 1000);

  const styleDiv = {
    width: largura,
    minWidth: largura,
    maxWidth: largura,
  };

  if (padB) styleDiv.paddingBottom = 24;

  const setOnMountInputs = useCallback(() => {
    if (onMountInputs && inputs.length > 0) {
      if (tbRef.current && isMounted.current) {
        onMountInputs(inputs, tbRef.current);
      }
    }
  }, [tbRef, isMounted, inputs, onMountInputs]);

  const setOnMountToogles = useCallback(() => {
    if (onMountToogles && toogles.length > 0) {
      if (tbRef.current && isMounted.current) {
        onMountToogles(toogles, tbRef.current);
      }
    }
  }, [tbRef, isMounted, toogles, onMountToogles]);

  useEffect(() => {
    setOnMountInputs();
    setOnMountToogles();
  }, [setOnMountInputs, setOnMountToogles]);

  return (
    <TableContext.Provider
      value={{
        registerRadio,
        radioCheck,
        radioChange,
        registerToogle,
        toogleChange,
      }}
    >
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={handleResize}
        refreshMode="debounce"
        refreshRate={300}
      />
      <PerfectScrollbar onSync={handleSync} ref={psRef}>
        <div style={styleDiv}>
          <ReactTable {...props} ref={tbRef} />
        </div>
      </PerfectScrollbar>
    </TableContext.Provider>
  );
}
TableDef.propTypes = {
  name: PropTypes.string,
  onCheck: PropTypes.func,
  onChange: PropTypes.func,
  onToogleChange: PropTypes.func,
  onMountInputs: PropTypes.func,
  onMountToogles: PropTypes.func,
  width: PropTypes.number,
};
TableDef.defaultProps = {
  name: 'noNamedTable',
  onCheck: () => {},
  onChange: () => {},
  onToogleChange: () => {},
  onMountInputs: null,
  onMountToogles: null,
  width: null,
};
