import PropTypes from 'prop-types';

export const columnsItemShape = {
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  component: PropTypes.any,
  sortable: PropTypes.bool,
  value: PropTypes.any,
  propData: PropTypes.string,
};
