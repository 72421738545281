import { urlEncodeObject } from 'mini-helper';
import api from '~/services/Api';
import { normalizePayload } from '~/components/TableDef/defaultOptions';

export function prepareFormData(formData) {
  const { idgestor, nivel } = formData;
  return {
    ...formData,
    idgestor: parseInt(idgestor, 10) || 0,
    nivel: parseInt(nivel, 10) || 3,
  };
}

export async function getGestores(payload) {
  const result = await api.post('/gestores/list', normalizePayload(payload));
  return result && result.data;
}

export async function getGestor(idgestor) {
  const query = idgestor ? `?${urlEncodeObject({ idgestor })}` : '';
  const result = await api.get(`/gestor/${query}`);
  return result && result.data;
}

export async function sendGestor(formData) {
  if (!formData) return false;
  const result = await api.post('/gestor/store', prepareFormData(formData));
  return result && result.data;
}

export async function sendManagerActivate(idgestor, active) {
  if (!idgestor || idgestor <= 0) return null;
  const result = await api.post(`/gestores/activate`, {
    idgestor,
    active,
  });
  return result && result.data;
}

export async function getGestorToDelete(id) {
  const idgestor = parseInt(id, 10) || 0;
  if (idgestor <= 0) return null;
  const q = idgestor > 0 ? `?idgestor=${idgestor}` : '';
  const result = await api.get(`/gestor/delete/${q}`);
  return result && result.data;
}

export async function sendGestorToDelete(formData) {
  if (!formData) return false;
  const prepare = () => {
    return {
      ...formData,
      idgestor: parseInt(formData.idgestor, 10) || 0,
    };
  };
  const result = await api.post('/gestor/delete', prepare());
  return result && result.data;
}
