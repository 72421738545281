import React from 'react';
// import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { Form } from '@rocketseat/unform';

// COMPONENTS
import { ServerLogo } from '~/components/_layout/Images';
import { useReducerAuth } from '~/store/hooks/auth';
import { useReducerTheme } from '~/store/hooks/theme';
import { FormContent, Grupo, Campo, LinkCampo, ButtonCampo } from '~/components/FormDomus';

import Version from './Version';

// import ImageLogoDS from '~/assets/images/logo-des.svg';

export default function Login() {
  const [authData, { dispatchLogin }] = useReducerAuth();
  const [{ data: theme }] = useReducerTheme();
  const imgLogo = theme.assets ? theme.assets.logo || null : null;

  const { loading, signed, token, level, user = {} } = authData;

  function handleSubmit(dataForm) {
    dispatchLogin(dataForm);
  }

  if (signed && token && user) {
    const { key, pwHasBeenReset, nivel } = user;
    const levelAccess = level || nivel || 0;
    if (key && !pwHasBeenReset) {
      // return <Redirect to={`/resetpassword?key=${user.key}&mode=firsttime`} />;
    }

    if (levelAccess < 7) return <Redirect to="/sindico" />;
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <FormContent className="formContent">
        <div className="fadein">
          <ServerLogo url={imgLogo} alt="LOGO" className="logo" />
          <Form onSubmit={handleSubmit}>
            <Campo type="email" name="email" lbicon="email" placeholder="seu e-mail" />
            <Campo
              type="password"
              name="password"
              lbicon="password"
              placeholder="sua senha"
              autoComplete="current-password"
            />
            <Grupo className="fixed">
              <LinkCampo wchild="w50" to="esqueci">
                ESQUECI A SENHA
              </LinkCampo>
              <ButtonCampo
                type="submit"
                wchild="w50"
                className="bgcontrast hover"
                disabled={loading}
              >
                {loading ? `...aguarde` : 'ENVIAR'}
              </ButtonCampo>
            </Grupo>
          </Form>
        </div>
      </FormContent>
      <Version />
    </>
  );
}

Login.propTypes = {
  // location: PropTypes.object,
};

Login.defaultProps = {
  // location: null,
};
