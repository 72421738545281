import React, { useMemo } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CircleLoading } from 'avatardomus-components';
import { useReducerAuth } from '~/store/hooks/auth';
import { useReducerTheme } from '~/store/hooks/theme';

import { adminRouteList, devRoutesList, sindicoRouteList } from './private';
import { publicRouteList } from './public';

import DocumentTitle from '~/components/DocumentTitle';

export function ThemeLoading() {
  const [{ loading }] = useReducerTheme();
  if (loading) return <CircleLoading size={72} />;
  return null;
}

export default function Routes() {
  const [{ signed, token, user = {} }] = useReducerAuth();
  const levelAccess = user && user.nivel ? user.nivel || 0 : 0;
  // const [shouldRefresh, setShouldRefresh] = useState(false);

  const allRoutes = useMemo(() => {
    return [...sindicoRouteList, ...devRoutesList, ...adminRouteList, ...publicRouteList];
  }, []);

  function BuildRoute(p) {
    const logged = !!(signed && token);
    const { component: Component, layout: Layout, privated, ...rest } = p;

    if (!logged && privated) return <Redirect to="/" />;
    if (levelAccess && levelAccess < p.minLevel) return <Redirect to="/" />;

    // console.log('BuildRoute', rest);
    return (
      <Layout>
        <DocumentTitle title={rest.name || 'nonamed route'} />
        <Component {...rest} />
      </Layout>
    );
  }

  function mapPath(arrayPath, rota) {
    return arrayPath.map((p) => {
      const k = `route-dulble-${Math.random()}`;
      const irota = {
        ...rota,
        path: p,
      };
      // console.log('mapPath', irota);
      return (
        <Route
          key={k}
          exact={irota.exact}
          path={p}
          component={(routeProp) => <BuildRoute {...routeProp} {...irota} />}
        />
      );
    });
  }

  function mapRotas(array) {
    return array.map((rota) => {
      const { subroute, ...restR } = rota;
      if (subroute && subroute.length > 0) {
        return mapRotas([...subroute, { ...restR }]);
      }
      const { path, exact } = rota;
      if (Array.isArray(path) && path.length > 0) {
        return mapPath(path, rota);
      }
      // console.log('mapRotas', rota);
      return (
        <Route
          key={`route-${Math.random()}`}
          exact={exact}
          path={path}
          component={(routeProp) => <BuildRoute {...routeProp} {...rota} />}
        />
      );
    });
  }

  return (
    <>
      <BrowserRouter>
        <Switch>{mapRotas(allRoutes)}</Switch>
      </BrowserRouter>
      <ThemeLoading />
    </>
  );
}
