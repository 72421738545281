import React from 'react';

// import { LabelIconsContent, LabelIconsItem } from '~/components/LabelIcons';
import { LabelIconsItem, LabelButtonContent } from '~/components/LabelButtonIcon';

export default function MenuIcons() {
  return (
    <LabelButtonContent>
      <LabelIconsItem icon="me" label="MEUS DADOS" link="/sindico/aboutme" />
      <LabelIconsItem icon="documentos" label={`RELAT\u004fRIOS OFFLINE`} link="/sindico/files" />
      <LabelIconsItem icon="gestor" label={`USU\u00c1RIOS`} link="/sindico/gestores" disabled />
      {/* <LabelIconsItem label="DISPOSITIVOS" disabled />
        <LabelIconsItem label="MORADORES" disabled /> */}
    </LabelButtonContent>
  );
}
