import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';

import Td from './Td';
import TableContext from './TableContext';

function Tr({ row }) {
  const { colKeys } = useContext(TableContext);
  return (
    <tr>
      {colKeys.map((k, i) => {
        const key = `cell-${i}-${Math.random()}`;
        return <Td key={key} keyOfColumn={k} row={row} />;
      })}
    </tr>
  );
}

Tr.defaultProps = { row: {} };
Tr.propTypes = { row: PropTypes.object };

export default memo(Tr);
