import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestLogout, requestLogin, manualStorage } from '~/store/actions/auth';

export function useReducerAuth() {
  const stateAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const dispatchLogin = useCallback(
    (payload) => {
      dispatch(requestLogin(payload));
    },
    [dispatch],
  );

  const dispatchLogout = useCallback(() => {
    dispatch(requestLogout());
  }, [dispatch]);

  const setAuth = useCallback(
    (payload) => {
      dispatch(manualStorage(payload));
    },
    [dispatch],
  );

  return [stateAuth, { dispatchLogout, dispatchLogin, setAuth }];
}
