import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function LabelButton(props) {
  const { className, children, icon, lbicon, size, style, ...rest } = props;

  const classeB = cx('label-button', 'hover', { disabled: !!rest.disabled }, className);
  const sizes = size && { width: size, height: size };
  const styles = { ...style, ...sizes };

  return (
    <button type="button" data-lbicon={icon || lbicon} className={classeB} style={styles} {...rest}>
      {children}
    </button>
  );
}

LabelButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.string,
  lbicon: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};
LabelButton.defaultProps = {
  children: null,
  className: null,
  icon: null,
  lbicon: null,
  size: 44,
  style: {},
  disabled: false,
};
