import React from 'react';
import PropTypes from 'prop-types';
import { TableDiv, DivLine, DivCell, SupLabel, Text } from 'avatardomus-components';

const defPropTypes = {
  propTypes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultProps: '--',
};

export default function SummaryTotal({ total, mediana, medianaLabel }) {
  return (
    <TableDiv>
      <DivLine underline={false}>
        <DivCell>
          <SupLabel>{`Total no per\u00edodo:`}</SupLabel>
          <Text>{total || '--'}</Text>
        </DivCell>
        <DivCell>
          <SupLabel>{medianaLabel}:</SupLabel>
          <Text>{mediana || '--'}</Text>
        </DivCell>
        <DivCell> </DivCell>
      </DivLine>
    </TableDiv>
  );
}

SummaryTotal.propTypes = {
  total: defPropTypes.propTypes,
  mediana: defPropTypes.propTypes,
  medianaLabel: PropTypes.string,
};

SummaryTotal.defaultProps = {
  total: defPropTypes.defaultProps,
  mediana: defPropTypes.defaultProps,
  medianaLabel: `M\u00e9dia por dia`,
};
