import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './button-pagination.css';

export default function ButtonPagination({ children, className, ...rest }) {
  const classBtn = cx('button-pagination', 'bgsecundary', 'hover', className);
  return (
    <button type="button" {...rest} className={classBtn}>
      {children}
    </button>
  );
}
ButtonPagination.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
ButtonPagination.defaultProps = {
  children: null,
  className: null,
};
