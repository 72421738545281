import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// import chroma from 'chroma-js';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';
import { useReducerTheme } from '~/store/hooks/theme';

// COMPONENTS

export default function ChartBuffered({ emitted, mediana, width, height, useConn }) {
  const [{ data: theme }] = useReducerTheme();
  const { charts } = theme;

  const data = useMemo(() => {
    return emitted.map((e) => {
      return {
        date: e.date,
        eventos: e.count,
        guaritas: e.conn,
      };
    });
  }, [emitted]);

  function formatter(value, name, p) {
    const nm = name === 'eventos' ? 'Eventos' : 'Guaritas';
    return [value, nm, p];
  }

  function labelFormatter(label) {
    const result = moment(label, 'YYYY-MM-DD', true);
    if (result.isValid) {
      return `${result.format('ddd')} ${result.format('DD/MM/YYYY')}`;
    }
    return label;
  }

  if (!width || !height) return <div>--</div>;

  return (
    <div style={{ marginTop: 4 }}>
      <LineChart width={width} height={height} data={data}>
        <XAxis dataKey="date" hide />
        <YAxis dataKey="eventos" hide />
        <YAxis dataKey="guaritas" orientation="right" yAxisId="right" hide />
        <Line
          type="linear"
          dataKey="eventos"
          stroke={charts.primary}
          strokeWidth={1}
          dot={{ r: 1 }}
        />
        <Line
          type="linear"
          dataKey="guaritas"
          stroke={charts.secundary}
          strokeWidth={1}
          dot={{ r: 1 }}
          yAxisId="right"
          hide={!useConn}
        />
        {mediana && mediana.count ? (
          <ReferenceLine
            y={mediana.count || 0}
            stroke={charts.primaryComplement}
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ) : null}
        {mediana && mediana.conn && useConn ? (
          <ReferenceLine
            yAxisId="right"
            // label={`m\u00e9dia conn`}
            y={mediana.conn || 0}
            stroke={charts.secundaryComplement}
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ) : null}
        <Tooltip
          formatter={formatter}
          labelStyle={{ color: '#000' }}
          labelFormatter={labelFormatter}
        />
        <Legend />
      </LineChart>
    </div>
  );
}

ChartBuffered.propTypes = {
  emitted: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  // media: PropTypes.object,
  mediana: PropTypes.object,
  useConn: PropTypes.bool,
};

ChartBuffered.defaultProps = {
  emitted: [],
  // media: {},
  mediana: {},
  width: 0,
  height: 0,
  useConn: false,
};
