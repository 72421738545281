import { imgIcon } from 'avatardomus-components';
// import PageEventReport from '~/pages/Dashboard/Report/EventReport';
// import PageAccessReport from '~/pages/Dashboard/Report/AccessReport';
// import PageManagers from '~/pages/Dashboard/Gestores';
// import PageConfig from '~/pages/Dashboard/Configuration';
// import PageMe from '~/pages/Dashboard/Me';
// import About from '~/pages/About';
import PageLPR from '~/pages/Developer/Lpr';

import {
  LazyDashboardGuaritas as PageGuaritas,
  LazyDashboardConfig as PageConfig,
  LazyDashboardGestores as PageManagers,
  LazyDashboardMe as PageMe,
  LazyDashboardAccessReport as PageAccessReport,
  LazyDashboardEventReport as PageEventReport,
} from '~/routes/lazy/dashboard';

import AdminReportLayout from '~/pages/_layouts/AdminReport';
import PrintReportLayout from '~/pages/_layouts/PrintReport';
import { prepareRouteList } from '~/routes/routeHelpers';

const guaritaRoutes = [
  {
    path: '/guaritas/relatorio/:id',
    name: 'Relat\u00f3rio de eventos',
    icon: imgIcon.requireList.dashboard,
    component: PageEventReport,
    layout: AdminReportLayout,
    exact: true,
    privated: true,
    noMenu: true,
  },
];

const gestorRoutes = [
  {
    path: '/gestores/report/access/:id',
    name: `Relat\u00f3rio de acessos`,
    icon: imgIcon.requireList.dashboard,
    component: PageAccessReport,
    layout: PrintReportLayout,
    exact: true,
    privated: true,
    noMenu: true,
  },
];

const adminDashboardRoutes = [
  {
    path: '/config',
    name: `Configura\u00e7\u00f5es`,
    icon: imgIcon.requireList.config,
    component: PageConfig,
    layout: null,
    exact: true,
    minLevel: 8,
    // subroute: [],
  },
  {
    path: '/aboutme',
    name: 'Meus dados',
    icon: imgIcon.requireList.me,
    component: PageMe,
    layout: null,
    exact: true,
    // subroute: [],
  },
  {
    path: '/guaritas',
    name: 'Guaritas',
    icon: imgIcon.requireList.condominio,
    component: PageGuaritas,
    layout: null,
    exact: true,
    subroute: guaritaRoutes,
  },
  {
    path: '/gestores',
    name: 'Gestores',
    icon: imgIcon.requireList.gestor,
    component: PageManagers,
    layout: null,
    exact: true,
    subroute: gestorRoutes,
  },
  {
    path: '/lpr',
    name: `LPR test`,
    icon: imgIcon.requireList.documentos,
    component: PageLPR,
    layout: null,
    exact: true,
  },
  // {
  //   path: '/sobre',
  //   name: 'Sobre',
  //   icon: imgIcon.requireList.documentos,
  //   component: About,
  //   layout: null,
  //   exact: true,
  //   // subroute: [],
  // },
];

export default prepareRouteList(adminDashboardRoutes, true);
