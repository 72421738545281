import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ImageMessage } from 'avatardomus-components';

import FormCadastro from '~/pages/_common/FormCadastro';
import { useIsMounted } from '~/services/customHooks';
import { sendUserInvite } from '../makeData';

export default function InviteGestor({ data }) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const emailDisabled = !!(data.emok || true);

  const handleSubimit = useCallback(
    async (formData) => {
      setLoading(true);
      const result = await sendUserInvite(formData);
      if (isMounted.current) {
        setLoading(false);
        if (result.success) setSuccess(true);
      }
    },
    [isMounted],
  );
  return (
    <>
      {success ? (
        <ImageMessage type="Joia">
          <p className="c">
            Seu cadastro foi realizado com sucesso
            <br />
            {`Agora voc\u00ea j\u00e1 pode fazer login`}
            <br />
            <br />
            <Link to="/login">Clique aqui para entrar</Link>
          </p>
        </ImageMessage>
      ) : (
        <FormCadastro
          initialData={data}
          emailDisabled={emailDisabled}
          loading={loading}
          onFormSubmit={handleSubimit}
        />
      )}
    </>
  );
}

InviteGestor.defaultProps = {
  data: {},
};
InviteGestor.propTypes = {
  data: PropTypes.object,
};
