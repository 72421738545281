/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdVerifiedUser } from 'react-icons/md';

// import { TitleOnline } from '~/components/_layout';
import NewMenuLateralContext from './MenulateralContext';
import { useReducerAuth } from '~/store/hooks/auth';

export default function Title() {
  const [auth] = useReducerAuth();
  const { setOpenContext } = useContext(NewMenuLateralContext);

  function handleClose(e) {
    e.preventDefault();
    setOpenContext(false);
    e.stopPropagation();
  }
  return (
    <div className="title-container">
      <div className="title-container-name">
        <div>
          <MdVerifiedUser style={{ fontSize: '32px' }} />
        </div>
        <div>
          <p>{auth && auth.user ? auth.user.nome || '--' : '--'}</p>
        </div>
      </div>
      <div>
        <label onClick={handleClose} className="label-close" onKeyDown={handleClose}>
          <IoMdClose style={{ fontSize: '40px' }} />
        </label>
      </div>
    </div>
  );
}
