export const defaultProps = {
  searching: false,
  onSearch: () => {},
  searchButton: {
    hidden: false,
    icon: 'fileSearch',
    value: 0,
    onClick: () => {},
    activedColor: '#000',
  },
  delButton: {
    hidden: false,
    icon: 'del',
    value: 0,
    disabled: true,
    onClick: () => {},
  },
  updateButton: {
    hidden: false,
    icon: 'update',
    onClick: () => {},
  },
  editButton: {
    hidden: false,
    icon: 'edit',
    onClick: () => {},
    disabled: true,
  },
  addButton: {
    hidden: false,
    icon: 'add',
    onClick: () => {},
  },
};
