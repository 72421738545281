/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Mask from './Mask';

export default function CampoMask(props) {
  const { name, lbicon, campoClass, ...rest } = props;

  let cls = 'campo lbicon';
  cls += campoClass ? ` ${campoClass}` : '';
  if (rest.disabled) cls += ' disabled';

  return (
    <div className={cls}>
      <Mask name={name} className="primary" {...rest} />
      <label data-lbicon={lbicon} htmlFor={name} className="bgcontrast hover" />
    </div>
  );
}

CampoMask.propTypes = {
  name: PropTypes.string.isRequired,
  lbicon: PropTypes.string.isRequired,
  campoClass: PropTypes.string,
  // beforeMaskedStateChange: PropTypes.func,
};

CampoMask.defaultProps = {
  campoClass: null,
  // beforeMaskedStateChange: () => {},
};
