import { forceArray } from '~/helpers/formats';
import NoPage from '~/pages/NoPage';

/**
 * @typedef {Object} IRouteItem
 * @property {String} path
 * @property {String} name
 * @property {import('react').ReactSVGElement} icon
 * @property {ReactElement} component
 * @property {ReactElement} layout
 * @property {Boolean} exact
 * @property {Array<this>} subroute
 * @property {Boolean} privated
 */

function preparePath(parentPath, path) {
  if (Array.isArray(path)) {
    return path.map((p) => preparePath(parentPath, p));
  }
  const prePath = path && path.startsWith('/') ? path : `/${path}`;
  const url =
    parentPath && prePath.startsWith(parentPath) ? prePath : `${parentPath || ''}${prePath}`;
  return url;
}
/**
 * @function prepareRoute
 * @param {IRouteItem} route
 * @param {boolean} privated
 * @param {IRouteItem} parent route
 * @returns {IRouteItem} prepared
 */
function prepareRoute(route, privated = true, parent = {}) {
  const { layout, icon, path } = parent;
  if (route.subroute) delete route.subroute;
  return {
    ...route,
    path: preparePath(path, route.path),
    privated: !!privated,
    icon: route.icon || icon,
    layout: route.layout || layout,
    component: route.component || NoPage,
    minLevel: route.minLevel || parent.minLevel || 0,
  };
}
/**
 * @function prepareRouteList
 * @param {Array<IRouteItem>} list list of routes
 * @param {Boolean} privated
 * @param {IRouteItem} parent route
 * @returns {Array<IRouteItem>} prepared
 */
export function prepareRouteList(list, privated = true, parent = {}) {
  const result = [];

  for (let i = 0; i < list.length; i++) {
    let subroute = list[i].subroute ? forceArray(list[i].subroute) : [];
    if (subroute.length > 0) {
      subroute = prepareRouteList(list[i].subroute, privated, list[i]);
    }
    result.push(prepareRoute(list[i], privated, parent));

    // if (subroute.length > 0) return [...subroute, ...result];
    if (subroute.length > 0) {
      for (let index = 0; index < subroute.length; index++) {
        result.push(prepareRoute(subroute[index], privated, parent));
      }
    }
  }

  return result;
}
