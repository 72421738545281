import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import setStateCallback from 'use-state-with-callback';

import { useReducerAuth } from '~/store/hooks/auth';

// imports
import { onlineInRequest } from '~/store/actions/online';
import { useIsMounted } from '~/services/customHooks';
import Api from '~/services/Api';

async function getOnline(url, payload) {
  const result = await Api.post(url, payload);
  return result ? result.data || false : false;
}

export function useOnline({ url, payload, interval }) {
  const fetchIdRef = useRef(0);
  const isMounted = useIsMounted();
  const ref = useRef();
  const dispatch = useDispatch();
  const { online } = useSelector((state) => state);
  const [auth, { setAuth }] = useReducerAuth();

  const [data, setData] = useState({});
  const [erro, setErro] = useState(false);
  const [loading, setLoading] = setStateCallback(false, (sinal) => {
    dispatch(
      onlineInRequest({
        loading: sinal,
        url: sinal ? url : '',
      }),
    );
  });

  const fetchUrl = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;
    try {
      setLoading(true);
      const result = await getOnline(url, { fetchId, ...payload });
      if (isMounted.current) {
        setLoading(false);
        if (result) {
          const { tokenRenew, ...restData } = result;
          if (tokenRenew && tokenRenew !== auth.token) {
            setAuth({ token: tokenRenew });
          } else {
            setData(restData);
          }
        } else {
          setErro(!result);
        }
      }
    } catch (error) {
      if (isMounted.current) setErro(true);
    }
  }, [payload, setLoading, setErro, url, isMounted, auth.token, setAuth]);

  useEffect(() => {
    const start = () => {
      if (isMounted.current) {
        clearInterval(ref.current);
        ref.current = setInterval(
          () => {
            if (!online.loading && !erro) fetchUrl();
          },
          fetchIdRef.current ? interval || 10000 : 1000,
        );
      }
    };

    start();

    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  }, [online.loading, fetchUrl, interval, erro, isMounted]);

  return [data, loading];
}
