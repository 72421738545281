import Api from '~/services/Api';

export async function getEmitted(filter) {
  const result = await Api.post('/dashboard/status/emitted', { filter });
  if (!result || !result.data) return false;
  return result.data;
}

export async function getEmittedMakeup(filter) {
  const result = await Api.post('/dashboard/status/makeup', { filter });
  if (!result || !result.data) return false;
  return result.data;
}

export async function getDevicesCount(filter) {
  const result = await Api.post('/dashboard/devices/count', { filter });
  if (!result || !result.data) return false;
  return result.data;
}
