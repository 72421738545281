import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MainMenuLateral, LandMenuLateral } from './styles';
import Title from './Title';

import NewMenuLateralContext from './MenulateralContext';
import useWindowDimensions from '~/components/_layout/useWindowDimensions';

export default function MenuLateral({ children, width, theme, right }) {
  const { bodyHeight } = useWindowDimensions();
  const { isOpen, setOpenContext } = useContext(NewMenuLateralContext);

  const cfg = {
    left: !right,
    right: !!right,
    open: isOpen(),
    close: !isOpen(),
  };

  const handlelandClick = (e) => {
    e.preventDefault();
    setOpenContext(false);
    e.stopPropagation();
  };

  return (
    <>
      <LandMenuLateral
        className={cx('new-menu-lateral-land', cfg)}
        right={right}
        onClick={handlelandClick}
      />
      <MainMenuLateral
        width={width}
        bgColor={theme.bgsecundary && theme.bgsecundary.background}
        style={{ minHeight: bodyHeight }}
        className={cx('new-menu-lateral', cfg)}
        right={right}
      >
        <Title />
        <div className="new-menu-lateral-container">{children}</div>
      </MainMenuLateral>
    </>
  );
}

MenuLateral.defaultProps = {
  children: null,
  width: 240,
  theme: {},
  right: false,
};

MenuLateral.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  theme: PropTypes.object,
  right: PropTypes.bool,
};
