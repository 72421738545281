import { imgIcon } from 'avatardomus-components';
import SindicoLayout from '~/pages/_layouts/SindicoLayout';
import Home from '~/pages/Sindico/Home';

import sindicoSubrouteList from './sindicoSubrouteList';
import { prepareRouteList } from '~/routes/routeHelpers';

const adminRouteList = [
  {
    path: '/sindico',
    name: 'Dashboard',
    icon: imgIcon.requireList.dashboard,
    component: Home,
    layout: SindicoLayout,
    exact: true,
    subroute: sindicoSubrouteList,
    noMenu: true,
    minLevel: 6,
  },
];

export default prepareRouteList(adminRouteList, true);
