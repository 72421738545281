import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Text, TableDiv, DivLine, DivCell, SupLabel } from 'avatardomus-components';

export default function SummaryTypes({ devicesCountType, loading }) {
  const list = useMemo(() => {
    return devicesCountType.filter((f) => f.count && ![0, 4].includes(f.deviceType));
  }, [devicesCountType]);

  return (
    <TableDiv>
      <DivLine underline={false} fstart>
        {list && list.length ? (
          list.map((device) => {
            if (!device.count) return null;
            return (
              <DivCell key={`device-type-${device.deviceType}`} width="16.666%">
                <SupLabel title={device.label}>{device.friendly}:</SupLabel>
                <Text>{device.count || '--'}</Text>
              </DivCell>
            );
          })
        ) : (
          <DivCell>
            <SupLabel> </SupLabel>
            <Text>{loading ? 'aguarde...' : 'Nenhum dispositivo encontrado'}</Text>
          </DivCell>
        )}
      </DivLine>
    </TableDiv>
  );
}

SummaryTypes.defaultProps = {
  devicesCountType: [],
  loading: false,
};

SummaryTypes.propTypes = {
  devicesCountType: PropTypes.array,
  loading: PropTypes.bool,
};
