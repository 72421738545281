import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grupo, ButtonCampo, Campo } from 'avatardomus-components';
import { gotoColumn } from 'rollcolumn-react';

export default function Step2({
  loading,
  isHidden,
  // clearPassword
}) {
  function handleBack() {
    gotoColumn('rc-cadastro', 1, true);
  }

  const buildStyle = useCallback(() => {
    return {
      display: `${isHidden ? 'none' : 'block'}`,
      opacity: `${isHidden ? 0 : 1}`,
      animation: 'all 0.8s linear',
    };
  }, [isHidden]);

  useEffect(() => {}, []);

  return (
    <div style={buildStyle()}>
      <p className="c">Defina uma senha de acesso</p>
      <Grupo>
        <Campo
          type="password"
          name="password"
          lbicon="password"
          placeholder="senha"
          autoComplete="new-password"
        />
        <Campo
          type="password"
          name="rpassword"
          lbicon="password"
          placeholder="confirme a senha"
          autoComplete="new-password"
        />
      </Grupo>
      <Grupo fixed>
        <ButtonCampo wchild="w50" type="button" model="text" icon="back" onClick={handleBack}>
          VOLTAR
        </ButtonCampo>
        <ButtonCampo wchild="w50" type="submit" model="submit" disabled={!!loading}>
          {loading ? 'aguarde...' : 'GRAVAR'}
        </ButtonCampo>
      </Grupo>
    </div>
  );
}

Step2.defaultProps = {
  loading: false,
  // clearPassword: false,
  isHidden: false,
};
Step2.propTypes = {
  loading: PropTypes.bool,
  // clearPassword: PropTypes.bool,
  isHidden: PropTypes.bool,
};
