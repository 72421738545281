import chroma from 'chroma-js';
import { createGlobalStyle } from 'styled-components';

import 'react-circular-progressbar/dist/styles.css';

function prepareColor(theme) {
  const colorContrast = theme.secundary.color || theme.secundary;
  return chroma(colorContrast).alpha(0.5);
}

function prepareColorsName(theme, key) {
  const color = theme[key].color || theme[key];
  return chroma(color).alpha(1);
}

export default createGlobalStyle`

  body {
    background-color: ${(props) => props.theme.bgprimary.background};
    color: ${({ theme }) => prepareColorsName(theme, 'bgprimary')};
  }

  *:focus { outline: 0; }

  .primary{
    color:${({ theme }) => prepareColorsName(theme, 'primary')};
  }
  .bgprimary{
    background-color: ${(props) => props.theme.bgprimary.background};
    color: ${({ theme }) => prepareColorsName(theme, 'bgprimary')};
  }

  .secundary {
    color:${({ theme }) => prepareColorsName(theme, 'secundary')};
  }

  .bgsecundary{
    background-color: ${(props) => props.theme.bgsecundary.background};
    color: ${({ theme }) => prepareColorsName(theme, 'bgsecundary')};
  }

  .bgsecundary.hover:hover{
    background-color: ${(props) => props.theme.bgcontrast.background};
    color: ${({ theme }) => prepareColorsName(theme, 'bgcontrast')};
  }

  .contrast {
    color: ${({ theme }) => prepareColorsName(theme, 'contrast')};
  }

  .bgcontrast {
    background-color: ${(props) => props.theme.bgcontrast.background};
    color: ${({ theme }) => prepareColorsName(theme, 'bgcontrast')};
  }

  .bgcontrast-gradient {
     background: linear-gradient(to bottom, #000000 40%, #29b8e5 50%, #000000 60%);
     background: ${({ theme }) => {
       const a1 = theme.bgcontrast.background;
       const a3 = theme.bgprimary.background;
       return `radial-gradient(farthest-corner at 45px 45px , ${a3} 0%, ${a1} 100%);`;
     }}
  }

  /* FORMS COLOR */
  .hover{
    transition: all ease 0.2s;
  }

  label.hover:hover, button:hover, a.hover:hover, .btn:hover {
    background-color: ${(props) => props.theme.bgsecundary.background} !important;
    color: #FFF;
  }

  .campo input:focus{
    border: ${({ theme }) => prepareColorsName(theme, 'secundary')} 1px solid;

  }
  input:focus + .hover{
    background-color: ${(props) => props.theme.bgsecundary.background};
  }

  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 ${(props) => prepareColor(props.theme)};
  }
  .ReactTable .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 ${(props) => prepareColor(props.theme)};
  }
`;
