import { imgIcon } from 'avatardomus-components';
// import PageHome from '~/pages/Sindico/Home';
// import PageMe from '~/pages/Sindico/Me';
// import About from '~/pages/Sindico/About';
// import PageFiles from '~/pages/Sindico/Files';
// import PageGestores from '~/pages/Sindico/Gestores';

import {
  LazySindicoAbout as About,
  LazySindicoFiles as PageFiles,
  LazySindicoGestores as PageGestores,
  LazySindicoHome as PageHome,
  LazySindicoMe as PageMe,
} from '~/routes/lazy/sindico';

import { prepareRouteList } from '~/routes/routeHelpers';

const sindicoSubrouteList = [
  {
    path: '/',
    name: `\u00c1rea do s\u00edndico`,
    icon: imgIcon.requireList.dashboard,
    component: PageHome,
    layout: null,
    exact: true,
    // subroute:,
  },
  {
    path: '/aboutme',
    name: 'Meus dados',
    icon: imgIcon.requireList.me,
    component: PageMe,
    layout: null,
    exact: true,
    // subroute: [],
  },
  {
    path: '/gestores',
    name: `Usu\u00e1rios`,
    icon: imgIcon.requireList.gestor,
    component: PageGestores,
    layout: null,
    exact: true,
    // subroute: [],
  },
  {
    path: '/files',
    name: 'Arquivos PDF',
    icon: imgIcon.requireList.documentos,
    component: PageFiles,
    layout: null,
    exact: true,
    // subroute: [],
  },
  {
    path: '/sobre',
    name: 'Sobre',
    icon: imgIcon.requireList.info,
    component: About,
    layout: null,
    exact: true,
    // subroute: [],
  },
];

export default prepareRouteList(sindicoSubrouteList, true);
