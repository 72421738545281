import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import qs from 'query-string';
import { CircleLoading, ImageMessage } from 'avatardomus-components';

import InviteGestor from './InviteGestor';

import { useIsMounted } from '~/services/customHooks';
import { useReducerTheme } from '~/store/hooks/theme';
import { ServerLogo } from '~/components/_layout/Images';

import { getKeyInviteChecks } from './makeData';

export default function Invite() {
  const isMounted = useIsMounted();
  const [{ data: theme }] = useReducerTheme();
  const [loading, setloading] = useState(false);
  const [data, setData] = useState({ success: false });
  const imgLogo = theme.assets ? theme.assets.logo || null : null;
  const { key } = qs.parse(useLocation().search || {}) || {};

  const keyChecks = useCallback(async () => {
    setloading(true);
    const result = await getKeyInviteChecks({ key });
    if (isMounted.current) {
      setloading(false);
      if (result) {
        setData(result);
      }
    }
  }, [key, isMounted]);

  useEffect(() => {
    keyChecks();
  }, [keyChecks]);

  return (
    <>
      <div className="fadein" style={{ width: 500, maxWidth: '100%' }}>
        <ServerLogo url={imgLogo} alt="LOGO" className="logo" />
        <br />
        {data.type === 1 ? <InviteGestor data={data} /> : null}
        {data.error && data.error === 2 ? (
          <>
            <ImageMessage type="Expires">
              <p className="c">
                {data.message}
                <br />
                <Link to="/login">Voltar</Link>
              </p>
            </ImageMessage>
          </>
        ) : null}
        {loading ? <CircleLoading color={theme.secundary} /> : null}
      </div>
    </>
  );
}

Invite.defaultProps = {};
Invite.propsTypes = {};
