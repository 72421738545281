import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IoMdLink } from 'react-icons/io';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 6px 8px;
  margin: 0 auto;
  margin-bottom: 4px;
  position: relative;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  max-width: 320px;
`;

const ItemContent = styled.div`
  display: block;
  margin: 0;
  padding-right: 6px;
  position: relative;
  max-width: 100%;
`;

const ItemAction = styled.label`
  display: block;
  margin: 0;
  position: relative;
  max-width: 44px;
  cursor: pointer;
`;

const ItemTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const ItemDescription = styled.span`
  font-size: 16px;
`;

export default function ItemNotification({
  id,
  title,
  children,
  description,
  action,
  onActionClick,
}) {
  const router = useHistory();
  const { url, route } = action;

  const content = children || description;

  const handleRoute = useCallback(() => {
    onActionClick(id);
    if (route) router.replace(route);
  }, [router, route, onActionClick, id]);

  return (
    <ItemContainer>
      <ItemContent>
        <ItemTitle>{title}</ItemTitle>
        {content ? (
          <>
            <br />
            <ItemDescription>{content}</ItemDescription>
          </>
        ) : null}
      </ItemContent>
      {url ? (
        <ItemAction>
          <IoMdLink />
        </ItemAction>
      ) : null}
      {route ? (
        <ItemAction onClick={handleRoute}>
          <IoMdLink size={32} />
        </ItemAction>
      ) : null}
    </ItemContainer>
  );
}

ItemNotification.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  children: PropTypes.any,
  description: PropTypes.string,
  action: PropTypes.object,
  onActionClick: PropTypes.func,
};
ItemNotification.defaultProps = {
  id: 0,
  title: ' ',
  children: null,
  description: null,
  action: {},
  onActionClick: async () => {},
};
