import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ItemTitle } from 'avatardomus-components';

import { useChartsOptions } from './ChartsContext';
import { ToolsFooter } from './styles';
import ToolBarSelectGuarita from './ToolBarSelectGuarita';

function FooterCondominio({ onChange, name }) {
  const { guaritaOptions } = useChartsOptions();

  return (
    <>
      {guaritaOptions && guaritaOptions.length > 1 ? (
        <ToolsFooter>
          <ItemTitle text={'Condom\u00ednio'} />
          <ToolBarSelectGuarita name={name} onChange={onChange} />
        </ToolsFooter>
      ) : null}
    </>
  );
}

FooterCondominio.defaultProps = {
  onChange: () => {},
  name: 'nonamed',
};

FooterCondominio.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default memo(FooterCondominio);
