/* eslint-disable no-plusplus */
import { toast } from 'react-toastify';

export function tostifyResults(results, options) {
  if (!results) return false;
  try {
    const notify = results.notify || results.data || results || false;
    if (!notify) return false;

    const opt = options || { autoClose: 1500 };

    if (Array.isArray(notify)) {
      return notify.map((n, i) => {
        const ii = (i + 2) * 100;
        return tostifyResults(n, { autoClose: opt.autoClose + ii });
      });
    }

    const { message, success, ...rest } = notify;
    let { type, error } = rest;
    error = parseInt(rest.error, 10) || 0;

    if (!type) type = 'error';
    if (!error || error <= 0 || success) {
      type = type || 'success';
    }

    const toasttype = (t, m, o) => {
      if (toast[t]) {
        toast[t](m, o);
        return true;
      }
      return false;
    };

    if (!message && error > 0 && !success) {
      return toasttype(type, `erro indefinido (${error})`);
    }

    if (Array.isArray(message)) {
      let r = 0;
      message.map((msg, j) => {
        const jj = (j + 2) * 100;
        if (toasttype(type, msg, { autoClose: opt.autoClose + jj })) r++;
        return r;
      });
      return !!(r > 0);
    }

    if (message) return toasttype(type, message, opt);

    return false;
  } catch (error) {
    return false;
  }
}
