import { combineReducers } from 'redux';

import app from './app';
import auth from './auth';
import guarita from './guarita';
import manager from './manager';
import theme from './theme';
import ids from './ids';
import online from './online';

export default combineReducers({
  app,
  auth,
  guarita,
  manager,
  theme,
  ids,
  online,
});
