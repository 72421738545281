import React, { memo } from 'react';

import { ServerLogo } from '~/components/_layout/Images';
import { useReducerTheme } from '~/store/hooks/theme';

const HeaderLogo = () => {
  const [{ data: theme }] = useReducerTheme();
  const imglogo = theme.assets ? theme.assets.logo || null : null;
  return (
    <div>
      <ServerLogo url={imglogo} className="header-logo" alt="LOGO" />
    </div>
  );
};

export default memo(HeaderLogo);
