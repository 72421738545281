/* eslint-disable no-restricted-properties */

/**
 * @function forceArray
 * @param {any} value
 * @returns {Array}
 */
export function forceArray(value) {
  if (!value) return [];
  return !Array.isArray(value) ? [value] : value;
}

/**
 * @function formatBytes
 * @param {Number} bytes numero em bytes
 * @param {Number} decimals casas decimais
 * @example
 * // formatBytes(bytes, decimals)
 * formatBytes(1024);       // 1 KB
 * formatBytes('1024');     // 1 KB
 * formatBytes(1234);       // 1.21 KB
 * formatBytes(1234, 3);    // 1.205 KB
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Arredonda numero com casas decimais
 * @function round
 * @param {Number} number
 * @param {Number} precision
 * @example
 * round(1234.5678, 1); // 1234.6
 */
export function round(number, precision = 4) {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

/**
 * Date object to JSON
 * @param {Date} d
 * @returns {String}
 */
export function convertDateJson(d) {
  const newDate = d instanceof Date ? d.toJSON() : d;
  return newDate;
}
