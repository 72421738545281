/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CustomContext from '../CustomContext';

export default function FormContent(props) {
  const { children, className, customContext, ...rest } = props;

  return (
    <CustomContext.Provider value={customContext}>
      <div className={classNames('formDomus', className)} {...rest}>
        {children}
      </div>
    </CustomContext.Provider>
  );
}

FormContent.propTypes = {
  children: PropTypes.any,
  customContext: PropTypes.object,
  className: PropTypes.string,
};

FormContent.defaultProps = {
  children: null,
  className: null,
  customContext: {},
};
