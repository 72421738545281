import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ToolItem from './ToolItem';

export default function ItemTitle(props) {
  const { children, className, text, ...rest } = props;
  const element = !text ? children : <h3>{text}</h3>;
  const classTools = classNames('toolsbar-title', className);
  return (
    <ToolItem {...rest} className={classTools}>
      {element}
    </ToolItem>
  );
}

ItemTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  text: PropTypes.string,
};
ItemTitle.defaultProps = {
  children: null,
  className: null,
  text: null,
};
