import api from '~/services/Api';

import { publicUrl } from '~/config';

export async function getAboutSys() {
  const result = await api.post(`/about`, {
    publicUrl,
    locationRef: window.location.href,
  });
  if (!result || !result.data) return false;
  return result.data;
}
