import { useState, useEffect } from 'react';

function getBodyDimensions() {
  const b = document.body;
  const html = document.documentElement;
  const width = Math.max(
    b.scrollWidth,
    b.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth,
  );

  const height = Math.max(
    b.scrollHeight,
    b.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  return { width, height };
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const bodyDimension = getBodyDimensions();
  return {
    width,
    height,
    bodyWidth: bodyDimension.width,
    bodyHeight: bodyDimension.height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
