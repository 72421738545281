/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { UniversalStyle as Style, StyleCacheProvider } from 'react-css-component';

// COMPONENTS

// IMPORTS
import { round } from '~/helpers/formats';
import TableContext from '~/components/TableDef/TableContext';

import './check-toogle.css';

function calculateSizes(altura, largura) {
  const w = largura || round(altura * 1.618, 0);
  const h = round(altura / 1.618, 0);
  const t = w - h;
  return {
    mainH: altura,
    mainW: w,
    inputH: h,
    inputW: w,
    label: h,
    translate: t,
  };
}

export default function Toogle(props) {
  const parentRef = useRef();
  const inputRef = useRef();

  const { registerToogle, toogleChange } = useContext(TableContext);

  const { uid, className, disabled, checked, name, value, onChange, height, width, row, title } = props;

  const buildID = () => {
    if (uid) return uid;
    if (name) return `toogle-${name}-${row.id || Math.random()}`;
    return `toogle-${row.id || value}`;
  };

  const id = buildID();

  const ids = {
    input: `check-${id}`,
    content: `check-content-${id}`,
    label: `check-label-${id}`,
  };

  const [sizes, setSizes] = useState(calculateSizes(height, width));

  const getParentHeight = useCallback(
    (ref) => {
      const { offsetHeight } = ref.parentNode;
      if (offsetHeight) setSizes(calculateSizes(height || offsetHeight, width));
    },
    [height, width],
  );

  useEffect(() => {
    if (parentRef.current) getParentHeight(parentRef.current);
  }, [getParentHeight]);

  useEffect(() => {
    const ref = inputRef.current || false;
    if (ref) {
      ref.value = value || row.id || '';
      ref.checked = !!checked;
      ref.disabled = !!disabled;
      registerToogle(ref);
    }
  }, [checked, disabled, registerToogle, row.id, value]);

  function handleChange(e) {
    if (e) onChange(e);
    toogleChange(e);
  }

  function buildCss(s) {
    return `#${ids.content} {
      max-height: ${s.mainH}px; height: ${s.mainH}px; width: ${s.mainW}px; min-width: ${s.mainW}px;
    }
    #${ids.label} {
      height: ${s.label}px; max-height: ${s.label}px; width: ${s.label}px; max-width: ${s.label}px;
    }
    #${ids.input}:checked + label {
      -o-transform: translate(${s.translate}px, -50%);
      -ms-transform: translate(${s.translate}px, -50%);
      -moz-transform: translate(${s.translate}px, -50%);
      -webkit-transform: translate(${s.translate}px, -50%);
      transform: translate(${s.translate}px, -50%);
    }
    `;
  }

  const clsD = { disabled: !!disabled };
  const classMain = cx('check-toogle-content', className);
  const classLabel = cx('check-toogle-label', 'bgsecundary', clsD);

  return (
    <StyleCacheProvider>
      <Style css={buildCss(sizes)} />
      <div id={ids.content} ref={parentRef} className={classMain}>
        <input
          ref={inputRef}
          type="checkbox"
          id={ids.input}
          className="check-toogle-input"
          name={name}
          onClick={handleChange}
          value={value}
        />
        <label id={ids.label} className={classLabel} htmlFor={ids.input} title={title} />
      </div>
    </StyleCacheProvider>
  );
}

Toogle.propTypes = {
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.object,
};

Toogle.defaultProps = {
  uid: null,
  className: null,
  checked: false,
  disabled: false,
  onChange: () => {},
  height: 0,
  width: null,
  value: '',
  name: null,
  row: {},
};
