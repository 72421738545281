import { urlEncodeObject } from 'mini-helper';
import api from '~/services/Api';

// GUARITA
export async function getGuarita(idguarita) {
  try {
    if (!idguarita) return false;
    // const q = idguarita ? `?id=${idguarita}` : false;
    const q = urlEncodeObject({ id: idguarita });
    // if (!q) return false;
    const result = await api.get(`/guaritasuser/get?${q}`);
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

/**
 * @function getGuaritaOptions
 * @param {boolean} useFiles
 */
export async function getGuaritaOptions(useFiles) {
  const query = `?useFiles=${useFiles ? '1' : ''}`;
  const result = await api.get(`/guaritasuser/options${query}`);
  return result.data;
}

// SCHEDULE FILES
export async function getGuaritaScheduleOptions(id) {
  try {
    const result = await api.get(`/guaritasuser/schedule/options${id ? `?id=${id}` : ''}`);
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function getGuaritaAlertNoFiles(id) {
  try {
    if (!id) return false;
    const result = await api.get(`/guaritasuser/schedule/getalertnofiles?id=${id}`);
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function sendGuaritaAlertNoFiles(payload) {
  try {
    if (!payload || (payload && !payload.guaritaId)) return false;
    const result = await api.post(
      `/guaritasuser/schedule/setalertnofiles?id=${payload.guaritaId}`,
      payload,
    );
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

// ALERT EVENTS
/**
 * @function getAlertList
 * @param {Number} idguarita
 * @param {Object} payload
 */
export async function getAlertList(idguarita, payload) {
  if (!idguarita || idguarita <= 0) return false;
  const result = await api.post(`/guaritasuser/hooks/list?id=${idguarita}`, payload);
  if (!result || !result.data) return false;
  return result.data;
}

/**
 * @function getAlert
 * @param {Number} idguarita
 * @param {Number} idalert
 */
export async function getAlert(idguarita, idalert) {
  if (!idguarita || idguarita <= 0) return false;
  const result = await api.post(`/guaritasuser/hooks/get?id=${idguarita}`, {
    idAlert: idalert,
  });
  if (!result || !result.data) return false;
  return result.data;
}

export function prepareDataDel(formData) {
  const { id, idAlert } = formData;
  return {
    id: id ? parseInt(id, 10) || 0 : 0,
    idAlert: idAlert ? parseInt(idAlert, 10) || 0 : 0,
  };
}

/**
 * @function deleteAlertItem
 * @param {Number} idguarita
 * @param {Object} payload
 */
export async function deleteAlert(idguarita, payload) {
  if (!idguarita || !payload.idAlert) return false;
  const result = await api.post(`/guaritasuser/hooks/delete?id=${idguarita}`, payload);
  if (!result || !result.data) return false;
  return result.data;
}
