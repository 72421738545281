import React from 'react';
import { Link } from 'react-router-dom';

import { ServerLogo } from '~/components/_layout/Images';
import { useReducerTheme } from '~/store/hooks/theme';
import { useReducerAuth } from '~/store/hooks/auth';
// import ImageLogoDS from '~/assets/images/logo-des.svg';

export default function Home() {
  const [{ data: theme }] = useReducerTheme();
  const imgLogo = theme.assets ? theme.assets.logo || null : null;

  const [{ signed, token, user = {} }] = useReducerAuth();

  let pathEnter = '/dashboard';
  if (user && user.nivel <= 6) pathEnter = '/sindico';

  return (
    <div className="fadein">
      <ServerLogo url={imgLogo} alt="LOGO" className="logo" style={{ display: 'block' }} />
      <p className="c">
        {signed && token ? (
          <Link to={pathEnter} className="c">
            entrar
          </Link>
        ) : (
          <Link to="/login" className="c">
            login
          </Link>
        )}
        {` `}|{` `}
        <Link to="/sobre" className="c">
          sobre
        </Link>
      </p>
    </div>
  );
}
