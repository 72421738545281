import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeIds } from '~/store/actions/ids';

export function useReducerIds(key) {
  const estado = useSelector((state) => state.ids);
  const dispatch = useDispatch();

  const disp = useCallback(
    (newID) => {
      dispatch(
        changeIds({
          [key]: newID,
        }),
      );
    },
    [dispatch, key],
  );
  return [estado[key], disp];
}

export function useReducerAlert() {
  const { alert } = useSelector((state) => state.ids);
  const dispatch = useDispatch();

  const disp = useCallback(
    (newID) => {
      dispatch(
        changeIds({
          alert: newID,
        }),
      );
    },
    [dispatch],
  );
  return [alert, disp];
}
