import styled from 'styled-components';

export const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
`;

export const ImgContainer = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
`;
