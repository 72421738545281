import React from 'react';
import PropTypes from 'prop-types';

import { Text, SupLabel, DivCell, DivLine, TableDiv } from 'avatardomus-components';

export default function FootTable({ total, endTime }) {
  return (
    <TableDiv>
      <DivLine fstart underline={false}>
        <DivCell width={72}>
          <SupLabel>Total:</SupLabel>
          <Text bold text={total || '--'} />
        </DivCell>
        <DivCell>
          <SupLabel>Tempo:</SupLabel>
          <Text text={endTime ? `${endTime}ms` : '--'} />
        </DivCell>
      </DivLine>
    </TableDiv>
  );
}

FootTable.defaultProps = {
  total: null,
  endTime: 0,
};
FootTable.propTypes = {
  total: PropTypes.number,
  endTime: PropTypes.number,
};
