import React, { useState, useEffect } from 'react';
import setStateCb from 'use-state-with-callback';

// COMPONENTS
import {
  CircleLoading,
  ToolsBar,
  ItemTitle,
  ItemButtons,
  ToolButton,
  FormContent,
  Grupo,
  Campo,
  ButtonCampo,
  LineToogle,
  CampoHidden,
} from 'avatardomus-components';
import { gotoColumn } from 'rollcolumn-react';

// IMPORTS
import { useIsMounted } from '~/services/customHooks';
import { useReducerIds } from '~/store/hooks/ids';
import { sendLPR, getLPR } from '~/services/Api/makeData/lpr';
import { panelName, defaultValues } from '~/pages/_common/LPR/makeData';

export default function FormLPR() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [lprId, setLprId] = useReducerIds('lpr');
  const [title, setTitle] = useState('');

  const [data, setData] = setStateCb({ lprId, ...defaultValues }, (dadosG) => {
    const t = dadosG.lprId > 0 ? `#${dadosG.lprId}` : 'Adicionar novo';
    setTitle(`${t}${dadosG.label ? `: ${dadosG.label}` : ''}`);
  });

  async function handleSubmit(formData) {
    setLoading(true);
    const result = await sendLPR(formData);
    if (isMounted.current) {
      setLoading(false);
      if (result.include && result.id > 0) setLprId(result.id);
      else if (result) setData((old) => ({ ...old, ...formData }));
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getLPR(lprId);
      if (isMounted.current) {
        setLoading(false);
        if (result) {
          setData(result);
        }
      }
    };
    fetchData();
  }, [lprId, setData, isMounted]);

  function handleBack() {
    gotoColumn(panelName, 1, true);
  }

  return (
    <>
      <ToolsBar line>
        <ItemTitle text={title} />
        <ItemButtons>
          <ToolButton title="Voltar" icon="back" onClick={handleBack} />
        </ItemButtons>
      </ToolsBar>
      <FormContent onSubmit={handleSubmit} initialData={data}>
        <CampoHidden name="lprId" type="hidden" />
        <Campo type="text" name="label" lbicon="name" placeholder="nome" />
        <LineToogle
          label="Ativado ou desativado:"
          width={44}
          name="actived"
          checked={!!data.actived}
        />
        <hr />
        <Grupo fixed>
          <div className="campo w50" />
          <ButtonCampo type="submit" wchild="w50" model="submit" disabled>
            {loading ? 'Salvando...' : 'SALVAR'}
          </ButtonCampo>
        </Grupo>
      </FormContent>
      {loading ? <CircleLoading /> : null}
    </>
  );
}

FormLPR.propTypes = {};
FormLPR.defaultProps = {};
