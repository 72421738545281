import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, Link } from 'react-router-dom';

import {
  ImageMessage,
  FormContent,
  Grupo,
  Campo,
  ButtonCampo,
  CampoHidden,
} from 'avatardomus-components';
import { useReducerTheme } from '~/store/hooks/theme';
import { useIsMounted } from '~/services/customHooks';
import { getAuthorization, sendResetPassword } from './makeData';

export default function FormEmail() {
  const isMounted = useIsMounted();
  const [{ data: theme }] = useReducerTheme();
  const [authorization, setAuthorization] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [toLogin, setToLogin] = useState(false);

  const getAuth = useCallback(async () => {
    setLoading(true);
    const result = await getAuthorization({ route: '/sessions/forgot' });
    if (isMounted.current) {
      setLoading(false);
      if (result && result.authorization) setAuthorization(result.authorization);
    }
  }, [isMounted]);

  async function formSubmit(formData) {
    setLoading(true);
    const result = await sendResetPassword(formData);
    if (isMounted.current) {
      setLoading(false);
      if (!result) setToLogin(true);
      if (result && result.success) setSent(formData.email);
    }
  }

  function backToLogin() {
    setToLogin(true);
  }

  useEffect(() => {
    getAuth();
  }, [getAuth]);

  if (toLogin) return <Redirect to="/login" />;

  return (
    <>
      <br />
      {sent ? (
        <>
          <div>
            <ImageMessage type="Sentmail">
              <p>
                Um e-mail foi enviado para <strong>{sent}</strong>{' '}
                {`contento as instru\u00e7\u00f5es de recupera\u00e7\u00e3o de senha`}
              </p>
            </ImageMessage>
            <br />
            <p className="c">
              <Link to="/login">fazer login</Link>
            </p>
          </div>
        </>
      ) : (
        <FormContent key={authorization} onSubmit={formSubmit} initialData={{ authorization }}>
          <CampoHidden name="authorization" />
          <Campo type="email" name="email" lbicon="email" placeholder="seu e-mail" />
          <Grupo fixed>
            <ButtonCampo
              type="button"
              wchild="w50"
              model="text"
              icon="back"
              customTheme={theme}
              onClick={backToLogin}
            >
              LOGIN
            </ButtonCampo>
            <ButtonCampo
              type="submit"
              wchild="w50"
              model="submit"
              disabled={loading}
              customTheme={theme}
            >
              {loading ? `...aguarde` : 'ENVIAR'}
            </ButtonCampo>
          </Grupo>
        </FormContent>
      )}
    </>
  );
}
