import api from '~/services/Api';
import { normalizePayload } from '~/components/TableDef/defaultOptions';

export async function getLPR(lprid = 0) {
  try {
    const id = !lprid || lprid <= 0 ? 0 : lprid;
    if (!id) return false;
    const result = await api.get(`/lpr${id ? `?lprid=${id}` : ''}`);
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function listLPR(payload) {
  try {
    const result = await api.post('/lpr/list', normalizePayload(payload));
    if (!result || !result.data) return false; // { page: 0, pages: 0, data: [] };
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function sendLPR(formData) {
  try {
    if (!formData) return false;
    const result = await api.post(`/lpr`, formData);
    if (!result || !result.data) return false;
    return result.data;
  } catch (error) {
    return false;
  }
}

export async function sendLprToDelete(formData) {
  if (!formData) return false;
  const id = formData.lprid || 0;

  const result = await api.delete(`/lpr?lprid=${id}`);
  if (!result || !result.data) return false;
  return result.data;
}

export async function sendLprActivate(lprid, actived) {
  if (!lprid || lprid <= 0) return false;
  const result = await api.post(`/lpr/activate`, {
    lprid,
    actived,
  });
  if (!result || !result.data) return false;
  return result.data;
}
