import { themeActions } from '~/store/actions/constants';

export function requestTheme(payload) {
  return {
    type: themeActions.requestTheme,
    payload,
  };
}

export function successTheme(payload) {
  return {
    type: themeActions.successTheme,
    payload,
  };
}

export function failureTheme(payload) {
  return {
    type: themeActions.failureTheme,
    payload,
  };
}
