/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useMemo } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { imgForm } from 'avatardomus-components';

export default function CampoDataList(props) {
  const { name, lbicon, campoClass, list, ...rest } = props;
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const classe = classNames('campo', 'lbicon', campoClass, {
    disabled: !!rest.disabled,
  });

  const styleLabel = {
    backgroundImage: `url(${imgForm.getImageByType(lbicon, true)})`,
  };

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
      });
    }
  }, [fieldName, registerField]);

  const datalistId = useMemo(() => `datalist-${name}`, [name]);

  return (
    <div className={classe}>
      <input
        id={name}
        ref={ref}
        name={name}
        list={datalistId}
        className="primary"
        defaultValue={defaultValue}
        {...rest}
      />
      <datalist id={datalistId}>
        {list.map((item) => {
          return (
            <option key={`key-${datalistId}-${item.id || Math.random()}`} value={item.title} />
          );
        })}
      </datalist>
      <label data-lbicon={lbicon} htmlFor={name} className="bgcontrast hover" style={styleLabel} />
    </div>
  );
}

CampoDataList.propTypes = {
  name: PropTypes.string.isRequired,
  lbicon: PropTypes.string,
  campoClass: PropTypes.string,
  list: PropTypes.array,
};

CampoDataList.defaultProps = {
  campoClass: null,
  lbicon: 'unknow',
  list: [],
};
