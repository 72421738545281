import React, { useContext } from 'react';

import TableContext from './TableContext';

export default function TFoot() {
  const { getColumns } = useContext(TableContext);

  const columns = getColumns();
  return (
    <tfoot>
      <tr>
        <td colSpan={columns.length} />
      </tr>
    </tfoot>
  );
}

TFoot.defaultProps = {
  // data: [],
};
TFoot.propTypes = {
  // data: PropTypes.array,
};
