import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { ServerLogo } from '~/components/_layout/Images';
import { useReducerTheme } from '~/store/hooks/theme';
import { useIsMounted } from '~/services/customHooks';
import { getAboutSys } from './makeData';
import config from '~/config';
import logoavatar from '~/assets/images/logo-avatar.svg';

export default function AboutPage() {
  const [{ data: theme }] = useReducerTheme();
  const imgLogo = theme.assets ? theme.assets.logo || null : null;
  const isMounted = useIsMounted();

  const [info, setInfo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState({});

  const fetchData = useCallback(async () => {
    const result = await getAboutSys();
    if (isMounted.current) {
      if (result) {
        const { company: dCompany, ...rest } = result;
        setInfo(rest);
        if (dCompany) setCompany(dCompany);
      }
    }
  }, [isMounted, setInfo, setCompany]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="fadein">
        <ServerLogo url={imgLogo} alt="LOGO" className="logo" />
        <br />
        <hr />
        <p className="c">
          <Link to="/" className="c">
            voltar
          </Link>
        </p>
        <hr />
        <p className="c">
          Front version: <strong>{config.appVersion || '--'}</strong>
          <br />
          Back version: <strong>{info.version || '--'}</strong>
          <br />
        </p>
        <a
          href="https://avatarsolucoesdigitais.com.br"
          target="_blank"
          rel="noopener noreferrer"
          title={`Avatar Solu\u00e7\u00f5es Digitais`}
        >
          <img
            src={logoavatar}
            alt="Avatar"
            style={{ maxWidth: 128, margin: '0px auto', padding: 0, display: 'block' }}
          />
        </a>
      </div>
    </>
  );
}
