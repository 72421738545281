import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Toogle } from '~/components/TableDef/Cells';

function CellToogle({ row, tableName }) {
  const key = `toogle-${tableName}-${row.id}`;
  return <Toogle uid={key} height={28} width={32} checked={!!row.actived} value={row.id || 0} />;
}
CellToogle.propTypes = { tableName: PropTypes.string, row: PropTypes.any };
CellToogle.defaultProps = { tableName: '', row: null };

export default memo(CellToogle);
