import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function ToolItem(props) {
  const { children, className, ...rest } = props;
  const classMain = classNames('toolsbar-item', className);
  return (
    <div className={classMain} {...rest}>
      {children}
    </div>
  );
}

ToolItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

ToolItem.defaultProps = {
  children: null,
  className: null,
};
