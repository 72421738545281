import React from 'react';
import PropTypes from 'prop-types';
import { ImageMessage } from 'avatardomus-components';

export default function MessageDelete({ lpr, label }) {
  return (
    <>
      {lpr > 0 ? (
        <ImageMessage type="Askcaution">
          <p className="c">
            <strong>Aten&ccedil;&atilde;o!</strong>
            <br />
            Essa opera&ccedil;&atilde;o n&atilde;o poder&aacute; ser revertida.
            <br />
            <br />
            Tem certeza que deseja excluir <strong>{`${label || `#${lpr}`}`}</strong>?
          </p>
        </ImageMessage>
      ) : (
        <ImageMessage type="Nofound">
          <p className="c">Nenhum LPR selecionado.</p>
        </ImageMessage>
      )}
    </>
  );
}
MessageDelete.propTypes = {
  lpr: PropTypes.number.isRequired,
  label: PropTypes.string,
};
MessageDelete.defaultProps = {
  label: null,
};
