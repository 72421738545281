import { appActions } from '~/store/actions/constants';

export function addApp(payload) {
  return {
    type: appActions.addApp,
    payload,
  };
}

export function changePageTitleApp(payload) {
  return {
    type: appActions.changePageTitle,
    payload,
  };
}
