import produce from 'immer';
import { onlineActions } from '~/store/actions/constants';

export const fetchDefaultState = {
  loading: false,
  url: '',
};

export default function onlineReducer(state = fetchDefaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case onlineActions.inRequest:
        draft.loading = action.payload.loading;
        draft.url = action.payload.url || '';
        break;
      default:
    }
  });
}
