import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { CircleLoading } from 'avatardomus-components';

export function LazySuspense({ children }) {
  return (
    <Suspense fallback={<CircleLoading color="#f00" size={24} text="baixando..." />}>
      {children}
    </Suspense>
  );
}

LazySuspense.propTypes = {
  children: PropTypes.any.isRequired,
};

const withLazy = (Component) => {
  const WithLazySuspense = (...props) => {
    return (
      <LazySuspense>
        <Component {...props} />
      </LazySuspense>
    );
  };
  return WithLazySuspense;
};

export default withLazy;
