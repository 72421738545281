import { authActions } from '~/store/actions/constants';

export function requestLogin(payload) {
  return {
    type: authActions.requestLogin,
    payload,
  };
}

export function successLogin(payload) {
  return {
    type: authActions.successLogin,
    payload,
  };
}

export function failureLogin(payload) {
  return {
    type: authActions.failureLogin,
    payload,
  };
}

export function requestLogout(payload) {
  return {
    type: authActions.requestLogout,
    payload,
  };
}

export function successLogout(payload) {
  return {
    type: authActions.successLogout,
    payload,
  };
}

export function manualStorage(payload) {
  return {
    type: authActions.manualStorage,
    payload,
  };
}
