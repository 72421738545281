import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

export default function LinkLi({ label, to, icon, activeOnlyWhenExact }) {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  const styles = { display: 'block' };
  if (icon) {
    styles.backgroundImage = `url(${icon})`;
    styles.paddingLeft = 32;
  }

  return (
    <li>
      <Link to={to} className={match ? 'active' : ''} style={styles}>
        {label}
      </Link>
    </li>
  );
}

LinkLi.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]).isRequired,
  activeOnlyWhenExact: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
};

LinkLi.defaultProps = {
  activeOnlyWhenExact: false,
  icon: null,
};
