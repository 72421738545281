import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useReducerApp } from '~/store/hooks/app';
import { useReducerTheme } from '~/store/hooks/theme';

export default function DocumentTitle(props) {
  // const app = useSelector((state) => state.app);
  const [app] = useReducerApp();
  const [{ name }] = useReducerTheme();
  const { title } = props;
  function getDocumentTitle() {
    const result = `${name || app.name} - ${title}`;
    return result;
  }

  // eslint-disable-next-line no-unused-vars
  const [docTitle, setDocTitle] = useState(getDocumentTitle());

  useEffect(() => {
    document.title = docTitle;
    return () => {
      document.title = '--';
    };
  }, [docTitle]);

  return null;
}
