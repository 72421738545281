import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { imgButton } from 'avatardomus-components';
import ToolItem from './ToolItem';

const ImagesButton = imgButton.requireList;

const getIcon = (key) => {
  return key in ImagesButton ? ImagesButton[key] : ImagesButton.unknow;
};

export default function ToolButton(props) {
  const { className, disabled, icon, ...rest } = props;

  const styles = {
    backgroundImage: `url(${getIcon(icon)})`,
  };

  const classBtn = classNames(
    'toolsbar-btn',
    'hover',
    {
      disabled: !!disabled,
    },
    className,
  );
  return (
    <ToolItem>
      <button
        type="button"
        className={classBtn}
        {...rest}
        disabled={disabled}
        style={styles}
      />
    </ToolItem>
  );
}

ToolButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

ToolButton.defaultProps = {
  className: null,
  disabled: false,
  icon: 'unknow',
  // type: 'button',
};
