/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useContext } from 'react';
import { IoMdMenu } from 'react-icons/io';

import NewMenuLateralContext from './MenulateralContext';

const IconMenu = (props) => {
  const { setToggleContext } = useContext(NewMenuLateralContext);

  const handleClick = (e) => {
    e.preventDefault();
    setToggleContext();
    e.stopPropagation();
  };
  return (
    <label>
      <IoMdMenu onClick={handleClick} {...props} />
    </label>
  );
};

export default memo(IconMenu);
