import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useReducerTheme } from '~/store/hooks/theme';

const Btn = styled.button`
  color: ${({ theme }) => theme.primary};
  :hover {
    background-color: ${({ theme }) => theme.contrast} !important;
  }
`;

export default function ButtonSair({ onClick }) {
  const [{ data: theme }] = useReducerTheme();
  function handleClick() {
    onClick();
  }
  return (
    <div className="new-menu-links-buttons pad4">
      <Btn theme={theme} id="btnLogout" type="button" className="primary" onClick={handleClick}>
        SAIR
      </Btn>
    </div>
  );
}

ButtonSair.propTypes = {
  onClick: PropTypes.func,
};
ButtonSair.defaultProps = {
  onClick: () => {},
};
