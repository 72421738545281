/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// CONPONENTS
import Header from '~/components/_layout/Header';
import Snakke from '~/components/Snakke';

export default function AdminReportLayout(props) {
  const { data: theme } = useSelector((state) => state.theme);

  const { children } = props;
  return (
    <>
      <Snakke progressColor={theme.contrast.color} />
      <Header className="center c" />
      {children}
    </>
  );
}

AdminReportLayout.propTypes = {
  children: PropTypes.any,
};
AdminReportLayout.defaultProps = {
  children: null,
};
