/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// CONPONENTS
import Header from '~/components/_layout/Header';
import Snakke from '~/components/Snakke';
import {
  ProviderMenu,
  MenuLateral,
  LinksMenu,
  IconMenu,
  // useMenuHooks,
} from '~/components/_layout/NewMenuLateral';
import Notify from '../Notify';

import { useReducerTheme } from '~/store/hooks/theme';
import { sindicoRouteList, adminRouteList, devRoutesList } from '~/routes/private';

export default function DashboardLayout(props) {
  const [{ data: theme }] = useReducerTheme();
  const { children } = props;
  return (
    <ProviderMenu>
      <MenuLateral width={240} theme={theme}>
        <LinksMenu
          sindicoRouteList={sindicoRouteList}
          adminRouteList={adminRouteList}
          devRoutesList={devRoutesList}
        />
      </MenuLateral>
      <Snakke progressColor={theme.contrast} fixed />
      <Header>
        <div className="header-tools">
          <div className="header-item-menu">
            <IconMenu />
          </div>
          <div>
            <Notify />
          </div>
        </div>
      </Header>
      {children}
    </ProviderMenu>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};
DashboardLayout.defaultProps = {
  children: null,
};
