import api from '~/services/Api';

export { getAuthorization } from '~/services/Api';

// /**
//  * @function getAuthorization
//  * @param {Object} payload
//  */
// export async function getAuthorization(payload) {
//   const result = await api.post(`/sessions/authorization`, payload);
//   if (!result || !result.data) return false;
//   return result.data;
// }

/**
 * @function sendResetPassword
 * @param {Object} payload
 */
export async function sendResetPassword(payload) {
  const result = await api.post(`/sessions/forgot`, payload);
  if (!result || !result.data) return false;
  return result.data;
}

/**
 * @function getKeyChecks
 * @param {Object} payload
 */
export async function getKeyChecks(payload) {
  const result = await api.post(`/sessions/keychecks`, payload);
  if (!result || !result.data) return false;
  return result.data;
}

export async function sendRedefined(payload) {
  const result = await api.post(`/sessions/resetpass`, payload);
  if (!result || !result.data) return false;
  return result.data;
}
