import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compareValues } from 'mini-helper';
import { getGuaritaOptions } from '~/services/Api/makeData/guarita';
import { useIsMounted } from '~/services/customHooks';
import { daysOptions as days, hoursOptions as hours } from '~/services/Api/makeData/common';

const ChartsContext = createContext();

export default ChartsContext;

export function ChartsProvider({ children }) {
  const [daysOptions, setDaysOptions] = useState(days);
  const [hoursOptions, setHoursOptions] = useState(hours);
  const [guaritaOptions, setGuaritaOptions] = useState([]);

  return (
    <ChartsContext.Provider
      value={{
        daysOptions,
        setDaysOptions,
        hoursOptions,
        setHoursOptions,
        guaritaOptions,
        setGuaritaOptions,
      }}
    >
      {children}
    </ChartsContext.Provider>
  );
}

ChartsProvider.defaultProps = {
  children: null,
};
ChartsProvider.propTypes = {
  children: PropTypes.any,
};

export function useChartsOptions() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const { daysOptions, hoursOptions, guaritaOptions, setGuaritaOptions } = useContext(
    ChartsContext,
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const guaritas = await getGuaritaOptions(true);
    if (isMounted.current) {
      if (guaritas && guaritas.guaritasOptions) {
        setGuaritaOptions(guaritas.guaritasOptions.sort(compareValues('title', 'asc')));
      }
      setLoading(false);
    }
  }, [isMounted, setGuaritaOptions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    hoursOptions,
    daysOptions,
    guaritaOptions,
  };
}
