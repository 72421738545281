import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ToolsBar, ItemTitle, CircleLoading } from 'avatardomus-components';
import ReactResizeDetector from 'react-resize-detector';

import { useIsMounted } from '~/services/customHooks';
import { getEmitted } from '~/services/Api/makeData/dashboard';

import ToolBarSelectDays from '../ToolBarSelectDays';
import SummaryTotal from '../SummaryTotal';
import FooterCondominio from '../FooterCondominio';
import { ChartContainer } from '../styles';

import CharEmitted from './CharEmitted';

export default function Events() {
  const isMounted = useIsMounted();
  const [filter, setFilter] = useState({ lastDays: 30, guaritaId: 0 });
  const [loading, setLoading] = useState(false);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const [data, setData] = useState({});

  const handleDayChange = useCallback((selectData) => {
    const value = parseInt(selectData.lastDays, 10) || 0;
    setFilter((oldFilter) => {
      return { ...oldFilter, lastDays: value };
    });
  }, []);

  const handleGuaritaChange = useCallback((selectData) => {
    const value = parseInt(selectData.eventsGuaritaId, 10) || 0;
    setFilter((oldFilter) => {
      return { ...oldFilter, guaritaId: value };
    });
  }, []);

  const handleResize = useCallback((w, h) => {
    setChartSize({ width: w, height: h });
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getEmitted(filter);
    if (isMounted.current) {
      if (result) setData(result);
      setLoading(false);
    }
  }, [isMounted, filter]);

  const total = useMemo(() => {
    if (data && data.emitted) {
      return data.emitted.reduce((acc, i) => {
        if (i.count) return acc + i.count;
        return acc;
      }, 0);
    }
    return 0;
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <ToolsBar line>
        <ItemTitle text="Fluxo de Eventos" />
        <ToolBarSelectDays onChange={handleDayChange} />
      </ToolsBar>
      <SummaryTotal total={total || 0} mediana={data && data.mediana ? data.mediana.count : 0} />
      <ChartContainer>
        <ReactResizeDetector onResize={handleResize} refreshMode="debounce" refreshRate={300} />
        <CharEmitted
          emitted={data.emitted || []}
          {...chartSize}
          mediana={data.mediana || {}}
          useConn={!filter.guaritaId}
        />
      </ChartContainer>
      <FooterCondominio onChange={handleGuaritaChange} name="eventsGuaritaId" />
      {loading ? <CircleLoading /> : null}
    </>
  );
}
