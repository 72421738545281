import React from 'react';
import styled from 'styled-components';

import config from '~/config';

const VersionContent = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  color: rgba(255, 255, 255, 0.5);
`;

export default function Version() {
  return <VersionContent>{config.appVersion}</VersionContent>;
}
