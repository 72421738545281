import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  ToolsBar,
  ItemTitle,
  ItemButtons,
  ToolButton,
  CircleLoading,
  TextOn,
} from 'avatardomus-components';

import { getDevicesCount } from '~/services/Api/makeData/dashboard';
import { useIsMounted } from '~/services/customHooks';

import SummaryTypes from './SummaryTypes';
import FooterCondominio from '../FooterCondominio';

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 126px;
`;

const FlexItem = styled.div`
  display: block;
  padding: 8px;
`;

const Text1 = styled(TextOn)`
  font-size: 32px;
`;

export default function DevicesCount() {
  const isMounted = useIsMounted();
  const [filter, setFilter] = useState({ guaritaId: 0 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getDevicesCount(filter);
    if (isMounted.current) {
      setLoading(false);
      if (result) setData(result);
    }

    // ...
  }, [isMounted, filter]);

  const handleGuaritaChange = useCallback((selectData) => {
    const value = parseInt(selectData.devicesGuaritaId, 10) || 0;
    setFilter((oldFilter) => {
      return { ...oldFilter, guaritaId: value };
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <ToolsBar line>
        <ItemTitle text="Dispositivos" />
        <ItemButtons>
          <ToolButton icon="update" onClick={fetchData} />
        </ItemButtons>
      </ToolsBar>
      <SummaryTypes devicesCountType={data.devicesCountType || []} loading={loading} />
      <FlexContainer>
        <FlexItem>
          <Text1>{data.devicesCount || '0'}</Text1>
        </FlexItem>
      </FlexContainer>
      <FooterCondominio onChange={handleGuaritaChange} name="devicesGuaritaId" />
      {loading ? <CircleLoading /> : null}
    </>
  );
}
