/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { imgForm } from 'avatardomus-components';
import stateCallback from 'use-state-with-callback';

export default function CampoSearch(props) {
  const ref = useRef();
  const { name, classname, placeholder, defaultValue, onSearch, auto, ...rest } = props;

  function handleSearch(term) {
    const nt = !term && ref.current ? ref.current.value || '' : term || '';
    return onSearch({ [name]: nt });
  }

  const [searchTerm, setSearchTerm] = stateCallback(defaultValue, (newterm) => {
    if (auto) handleSearch(newterm);
  });

  function handleChange(e) {
    const txt = e.target.value;
    setSearchTerm(txt);
  }

  function handleClear() {
    if (ref.current) {
      ref.current.value = '';
      setSearchTerm('');
    }
  }

  function keydown(e) {
    if (e.keyCode === 13 && !auto) handleSearch();
    if (e.keyCode === 27) handleClear();
  }

  const classe = cx('campo', 'search', classname, {
    disabled: !!rest.disabled,
  });

  const styleLabel = {
    backgroundImage: `url(${imgForm.getImageByType('search', true)})`,
  };

  return (
    <div className={classe}>
      <input
        ref={ref}
        type="text"
        name={name}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={keydown}
      />
      <button
        type="button"
        className="bgcontrast hover"
        style={styleLabel}
        onClick={() => !auto && handleSearch()}
        disabled={!!auto}
      />
    </div>
  );
}

CampoSearch.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  classname: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  auto: PropTypes.bool,
};

CampoSearch.defaultProps = {
  defaultValue: '',
  name: 'search',
  placeholder: 'digite sua busca',
  classname: null,
  onSearch: () => {},
  auto: false,
};
