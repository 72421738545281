import { toast } from 'react-toastify';
import { tostifyResults } from '~/helpers/messages';

export function buildNotify(responseNotify, opt) {
  if (Array.isArray(responseNotify)) {
    return responseNotify.map((notify) => {
      return buildNotify(notify);
    });
  }
  const type = responseNotify.type || 'error';
  const message = responseNotify.message || 'no message';
  if (Array.isArray(message)) {
    return message.map((m, i) => {
      const ii = (i + 1) * 100;
      const m1 = typeof m === 'object' ? m.message : m;
      return buildNotify({ type, message: m1 }, { autoClose: 1500 + ii });
    });
  }
  if (toast[type]) return toast[type](message, opt);
  return false;
}

export function buildErrors(responseData, opt) {
  const { notify, message } = responseData;
  if (notify) return buildNotify(notify);
  if (message) {
    if (Array.isArray(message)) {
      return message.map((msg, i) => {
        const ii = (i + 1) * 100;
        return toast.error(msg.message || msg, { autoClose: 1500 + ii });
      });
    }
    return toast.error(message.message || message, opt);
  }
  return false;
}

export async function onResponseError(error) {
  if (!error) return false;
  const code = parseInt(error.response && error.response.status, 10);
  const { url } = error && error.response ? error.response.config : 'unknow';

  if (!error.response) {
    const msg = `Verifique sua conex\u00e3o com a internet ${url}`;
    toast.error(msg);
    return Promise.resolve(false); // resolve sem erros
  }

  if (code === 401) {
    const { error: er, resolve } = error.response.data || error.response;
    if (er === 1000) {
      // window.location.href = '/logout';
      try {
        document.querySelectorAll('#btnLogout')[0].click();
        return true;
      } catch (err) {
        return false;
      }
    }

    if (resolve) {
      buildErrors(error.response.data || error.response);
      return Promise.resolve({ data: resolve });
    }

    return buildErrors(error.response.data || error.response);
  }

  if (code === 403) {
    const errorResponse = error.response.data || { message: `Erro de permiss\u00e3o ${url}` };
    return buildErrors(errorResponse, { autoClose: 2000 });
  }

  if (code === 404) {
    return buildErrors({ message: `Page not found ${url}` }, { autoClose: 1500 });
  }

  if (code >= 400) {
    toast.error(`Erro no servidor: ${code} ${url}`, {
      autoClose: 1500,
    });
  }
  return true;
}

export default function interceptorResponse(response) {
  const erros = parseInt(response.error, 10) || 0;
  if (response && erros > 0) buildErrors(response.data || response);
  tostifyResults(response.data || response);
  return response;
}
