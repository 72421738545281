import React from 'react';
import { ImageMessage } from 'avatardomus-components';

export default function NoPage() {
  return (
    <>
      <br />
      <ImageMessage type="Building">
        <p>
          Essa p&aacute;gina est&aacute; temporariamente indispon&iacute;vel.
          <br />
          Tente novamente mais tarde
        </p>
      </ImageMessage>
    </>
  );
}
