import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TableDiv, DivLine, DivCell, SupLabel, Text } from 'avatardomus-components';
import { Img, ImgContainer } from './styles';
import Waiting from './Waiting';
import NoSelected from '~/pages/_common/NoSelected';

function PlateDetails({ license, confidence, ipaddr, lastInteraction, online, imageFragmentFile }) {
  function buildSrcImage(str64) {
    return `data:image/png;base64,${str64}`;
  }
  return (
    <>
      {online ? (
        <>
          <TableDiv>
            <DivLine>
              <DivCell>
                <SupLabel>placa:</SupLabel>
                <Text bold>{license}</Text>
              </DivCell>
              <DivCell>
                <SupLabel>{`precis\u00e3o:`}</SupLabel>
                <Text bold>{confidence ? `${confidence}%` : '--'}</Text>
              </DivCell>
              <DivCell>
                <SupLabel>ip:</SupLabel>
                <Text bold>{ipaddr}</Text>
              </DivCell>
              <DivCell>
                <SupLabel>{`\u00faltima:`}</SupLabel>
                <Text bold>{lastInteraction}</Text>
              </DivCell>
            </DivLine>
          </TableDiv>
          <ImgContainer>
            {imageFragmentFile ? (
              <Img src={buildSrcImage(imageFragmentFile)} alt="" />
            ) : (
              <Waiting text="Aguardando..." height={140} />
            )}
          </ImgContainer>
        </>
      ) : (
        <NoSelected type="Offline" text="LPR offline" />
      )}
    </>
  );
}

PlateDetails.defaultProps = {
  license: '--',
  confidence: 0,
  ipaddr: '--',
  lastInteraction: '--',
  online: false,
  imageFragmentFile: null,
};
PlateDetails.propTypes = {
  license: PropTypes.string,
  confidence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ipaddr: PropTypes.string,
  lastInteraction: PropTypes.string,
  online: PropTypes.bool,
  imageFragmentFile: PropTypes.string,
};

export default memo(PlateDetails);
