import { whithElement, findParent, hasClass, findOneChildren } from '~/helpers/domUtils';

/**
 * @typedef TextOptionsForTable
 * @property {String} previousText
 * @property {String} nextText
 * @property {String} loadingText
 * @property {String} pageText
 * @property {String} ofText
 * @property {String} rowsText
 */

/** @constant {TextOptionsForTable} */
export const textOptions = {
  previousText: 'Anterior',
  nextText: 'Pr\u00f3xima',
  loadingText: 'Carregando...',
  noDataText: 'Nenhuma registro encontrado',
  pageText: '',
  ofText: 'de',
  rowsText: 'linhas',
};

/**
 * @typedef PayloadToFecth
 * @property {Number} page page namber
 * @property {Number} size page size
 * @property {String} orderby field for order index
 * @property {String} order order index asc || desc
 */

/** @constant {PayloadToFecth} */
export const defaultPayload = {
  page: 0,
  size: 10,
  orderby: 'id',
  order: 'asc',
};

export function clearInputs() {
  whithElement('.ctrl-linha', (el) => {
    el.checked = false;
  });
}

/**
 * @function clearInputsByName
 * @param {String} name
 * @returns {void}
 */
export function clearInputsByName(name) {
  whithElement(`input[name="${name}"]`, (el) => {
    el.checked = false;
  });
}

export function getInputInTr(trTarget) {
  if (!trTarget) return false;

  if (trTarget.nodeName === 'INPUT') {
    return trTarget;
  }

  if (hasClass(trTarget, 'rt-tr')) {
    return findOneChildren(trTarget, { nodeName: 'INPUT' });
  }

  // pega linha clicada
  const tr = findParent(trTarget, (e) => {
    return hasClass(e, 'rt-tr') ? e : false;
  });
  return tr ? findOneChildren(tr, { nodeName: 'INPUT' }) : false;
}

/**
 * Normaliza os dados para enviar ao servidor
 * @function normalizePayload
 * @param {PayloadToFecth} payload to normalize
 * @returns {PayloadToFecth} normalized
 */
export function normalizePayload(payload, defPayload = defaultPayload) {
  if (!payload) payload = defPayload;

  const { page, pageSize, sorted, size, fetchId } = payload;
  const { order: o, orderby: ob } = payload;
  let order; // = 'asc';
  let orderby; // = 'id';

  if (sorted && sorted.length > 0) {
    orderby = sorted[0].id || 'id';
    order = sorted[0].desc ? 'desc' : 'asc';
  }

  if (o || ob) {
    orderby = ob || orderby || undefined;
    order = o || order || undefined;
  }

  const result = {
    fetchId,
    page,
    size: pageSize || size,
    orderby,
    order,
    filter: payload.filter || {},
  };
  if (result.size < defPayload.size) result.size = defPayload.size;
  return result;
}

export function normalizeResponse(response, defPayload = defaultPayload) {
  const result = {
    pagination: {
      ...defPayload,
      total: 0,
      size: 0,
      pages: 0,
      page: 0,
    },
    data: [],
  };
  if (!response) return result;

  result.data = response.data || response.docs || response || [];
  const total = response.total || response.count || response.recordCount || 0;
  const size = response.size || response.pageSize || 10;
  result.pagination = {
    total,
    size,
    pages: response.pages || Math.ceil(total / size),
    page: response.page || 0,
    orderby: response.orderby,
    order: response.order,
  };

  return result;
}
