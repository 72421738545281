import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import { titleize } from 'mini-helper';

// import chroma from 'chroma-js';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';
import { useReducerTheme } from '~/store/hooks/theme';

// COMPONENTS

const Span = styled.div`
  color: ${({ cor }) => cor || '#000'};
  padding: 2px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2x;
`;

const ToolTip = styled.div`
  background-color: #fff;
  color: #000;
  padding: 4px;
  font-size: 16px;
`;

const CustomizedTooltip = ({ active, payload, label }) => {
  // console.log(active, payload, label);

  const d = moment(label).locale('pt-br');
  const h = d.hour();

  if (active) {
    return (
      <ToolTip>
        <div>
          <strong>
            {titleize(d.format('ddd'))} {d.format('DD/MM/YYYY')}
          </strong>
          <br />
          <i>{h}:00</i> {'\u00e0s'} <i>{h}:59</i>
        </div>
        <div>
          {payload.map((p) => {
            return (
              <div key={`${p.value}-${Math.random()}`}>
                <Span cor={p.color}>
                  {p.name}: <strong>{p.value}</strong>
                </Span>
              </div>
            );
          })}
        </div>
      </ToolTip>
    );
  }
  return null;
};

CustomizedTooltip.defaultProps = {
  active: false,
  payload: [],
  label: null,
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.any,
};
export default function ChartMakeup({ emitted, mediana, width, height, useConn }) {
  const [{ data: theme }] = useReducerTheme();
  const { charts } = theme;

  const data = useMemo(() => {
    return emitted.map((e) => {
      return {
        date: e.date,
        alarmes: e.count,
        guaritas: e.conn,
      };
    });
  }, [emitted]);

  // function formatter(value, name, p) {
  //   const nm = name === 'alarmes' ? 'Alarmes' : 'Guaritas';
  //   return [value, nm, p];
  // }

  // function labelFormatter(label) {
  //   // const result = moment(label, 'YYYY-MM-DD HH:mm:ss', true);
  //   const result = moment(label).locale('pt-br');
  //   const h = result.hour();
  //   if (result.isValid) {
  //     return `${result.format('ddd')} ${result.format('DD/MM/YYYY')} (${h}:00 as ${h}:59)`;
  //   }
  //   return label;
  // }

  if (!width || !height) return <div>--</div>;

  return (
    <div style={{ marginTop: 4 }}>
      <LineChart width={width} height={height} data={data}>
        <XAxis dataKey="date" hide />
        <YAxis dataKey="alarmes" hide />
        <YAxis dataKey="guaritas" orientation="right" yAxisId="right" hide />
        <Line
          type="linear"
          dataKey="alarmes"
          stroke={charts.contrast}
          strokeWidth={1}
          dot={{ r: 1 }}
        />
        <Line
          type="linear"
          dataKey="guaritas"
          stroke={charts.secundary}
          strokeWidth={1}
          dot={{ r: 1 }}
          yAxisId="right"
          hide={!useConn}
        />
        {mediana && mediana.count ? (
          <ReferenceLine
            y={mediana.count || 0}
            stroke={charts.contrastComplement}
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ) : null}
        {mediana && mediana.conn && useConn ? (
          <ReferenceLine
            yAxisId="right"
            // label={`m\u00e9dia conn`}
            y={mediana.conn || 0}
            stroke={charts.secundaryComplement}
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ) : null}
        {/* <Tooltip
          formatter={formatter}
          labelStyle={{ color: '#000' }}
          labelFormatter={labelFormatter}
        /> */}
        <Tooltip content={<CustomizedTooltip />} />
        <Legend />
      </LineChart>
    </div>
  );
}

ChartMakeup.propTypes = {
  emitted: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  // media: PropTypes.object,
  mediana: PropTypes.object,
  useConn: PropTypes.bool,
};

ChartMakeup.defaultProps = {
  emitted: [],
  // media: {},
  mediana: {},
  width: 0,
  height: 0,
  useConn: false,
};
