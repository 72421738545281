import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { gotoColumn } from 'rollcolumn-react';
import MessageDelete from './MessageDelete';
import { FormContent, Grupo, ButtonCampo } from '~/components/FormDomus';

import { useIsMounted } from '~/services/customHooks';
import { useReducerIds } from '~/store/hooks/ids';
import { panelName } from '~/pages/_common/Gestores/makeData';

import { getGestorToDelete, sendGestorToDelete } from '~/services/Api/makeData/gestor';

export default function DeleteGestor() {
  const isMounted = useIsMounted();
  const [gestor, setGestor] = useReducerIds('gestor');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ idgestor: gestor });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getGestorToDelete(gestor);
    if (isMounted.current) {
      setLoading(false);
      if (result) {
        setData(result);
      }
    }
  }, [gestor, isMounted]);

  async function handleSubmit(formData) {
    setLoading(true);
    const result = await sendGestorToDelete(formData);
    if (isMounted.current) {
      setLoading(false);
      if (result && result.success) {
        setGestor(-2);
        gotoColumn(panelName, 1, true);
      }
    }
  }

  function handleBack() {
    gotoColumn(panelName, 1, true);
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <br />
      <MessageDelete gestor={gestor} label={data.label} />
      <FormContent>
        <Form onSubmit={handleSubmit} initialData={data}>
          <Input name="idgestor" type="hidden" />
          <Input name="authorization" type="hidden" />
          <hr />
          <Grupo className="fix">
            <ButtonCampo
              type="button"
              wchild="w50"
              className="bgcontrast hover"
              onClick={handleBack}
            >
              CANCELAR
            </ButtonCampo>
            {gestor > 0 ? (
              <ButtonCampo type="submit" wchild="w50" className="button btnText" disabled={loading}>
                {loading ? 'Salvando...' : 'SIM, CONTINUE'}
              </ButtonCampo>
            ) : null}
          </Grupo>
        </Form>
      </FormContent>
      <br />
    </>
  );
}
