import { lazy } from 'react';
import withLazy from '~/components/LazySuspense';

export const LazyDashboardHome = withLazy(lazy(() => import('~/pages/Dashboard/Home')));
export const LazyDashboardConfig = withLazy(lazy(() => import('~/pages/Dashboard/Configuration')));
export const LazyDashboardMe = withLazy(lazy(() => import('~/pages/Dashboard/Me')));
export const LazyDashboardGuaritas = withLazy(lazy(() => import('~/pages/Dashboard/Guaritas')));
export const LazyDashboardGestores = withLazy(lazy(() => import('~/pages/Dashboard/Gestores')));

export const LazyDashboardEventReport = withLazy(
  lazy(() => import('~/pages/Dashboard/Report/EventReport')),
);
export const LazyDashboardAccessReport = withLazy(
  lazy(() => import('~/pages/Dashboard/Report/AccessReport')),
);
