import { imgIcon } from 'avatardomus-components';
// import DevPageGuaritas from '~/pages/Developer/Guaritas';
// import DevPageCompany from '~/pages/Developer/Company';
// import DevPageUsers from '~/pages/Developer/Users';
// import DevPageLpr from '~/pages/Developer/Lpr';
// import DevpageMaxbot from '~/pages/Developer/Maxbot';

import {
  LazyDeveloperCompany as DevPageCompany,
  LazyDeveloperGuaritas as DevPageGuaritas,
  LazyDeveloperLpr as DevPageLpr,
  LazyDeveloperMaxbot as DevpageMaxbot,
  LazyDeveloperUsers as DevPageUsers,
} from '~/routes/lazy/develop';

import { prepareRouteList } from '~/routes/routeHelpers';

const devSubrouteList = [
  {
    path: '/guaritas',
    name: 'Guaritas',
    icon: imgIcon.requireList.condominio,
    component: DevPageGuaritas,
    layout: null,
    exact: true,
  },
  {
    path: '/companies',
    name: 'Empresas',
    icon: imgIcon.requireList.company,
    component: DevPageCompany,
    layout: null,
    exact: true,
  },
  {
    path: '/users',
    name: `Usu\u00e1rios`,
    icon: imgIcon.requireList.gestor,
    component: DevPageUsers,
    layout: null,
    exact: true,
  },
  {
    path: '/lpr',
    name: `LPR`,
    icon: imgIcon.requireList.documentos,
    component: DevPageLpr,
    layout: null,
    exact: true,
  },
  {
    path: '/maxbot',
    name: `Maxbot`,
    icon: imgIcon.requireList.documentos,
    component: DevpageMaxbot,
    layout: null,
    exact: true,
  },
];

export default prepareRouteList(devSubrouteList, true);
