/* eslint-disable react/prop-types */
import React, { useCallback, useState, memo } from 'react';
import LabelButton from '~/components/_layout/LabelButton';

import { useIsMounted } from '~/services/customHooks';
// import { useReducerIds } from '~/store/hooks/ids';
import { sendInviteGestor } from '~/services/Api/makeData/gestor/invite';

function CellTools(props) {
  const { original, row } = props;
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const gId = parseInt(row.id || original.id || 0, 10);

  const handleSendInvite = useCallback(
    async (e) => {
      setLoading(true);
      const gestorId = parseInt(e && e.target ? e.target.value || 0 : 0, 10);
      await sendInviteGestor({ gestorId });
      if (isMounted.current) setLoading(false);
    },
    [isMounted],
  );

  return (
    <div>
      <LabelButton
        title="reenviar"
        lbicon="resend"
        size={32}
        value={gId}
        onClick={handleSendInvite}
        disabled={loading}
      />
    </div>
  );
}

CellTools.defaultProps = {};
CellTools.propTypes = {};

export default memo(CellTools);
