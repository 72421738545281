import { imgIcon } from 'avatardomus-components';
import AdminDashboardLayout from '~/pages/_layouts/AdminDashboard';
// import DevHome from '~/pages/Developer/DevHome';

import { prepareRouteList } from '~/routes/routeHelpers';
import devSubrouteList from '~/routes/private/devSubrouteList';

const devRouteList = [
  {
    path: '/developer',
    name: 'Developer',
    icon: imgIcon.requireList.dashboard,
    // component: DevHome,
    layout: AdminDashboardLayout,
    exact: true,
    subroute: devSubrouteList,
    noMenu: true,
    minLevel: 9,
  },
];

export default prepareRouteList(devRouteList, true);
