import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function LinkCampo(props) {
  const { children, wchild, ...rest } = props;
  return (
    <div className={classNames('campo', wchild)}>
      <Link className="button btnText" {...rest}>
        {children}
      </Link>
    </div>
  );
}

LinkCampo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  wchild: PropTypes.string,
};

LinkCampo.defaultProps = {
  children: null,
  wchild: null,
};
