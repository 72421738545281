/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useRouteMatch } from 'react-router-dom';
import { IoIosNotifications } from 'react-icons/io';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';

import { useOnline } from '~/services/Api/Online';
import config, { apiURL } from '~/config';
import { useReducerAuth } from '~/store/hooks/auth';
import { useSocketUser } from '~/components/_layout/useSocketIo';

import ItemNotification from './ItemNotification';

const Label = styled.label`
  padding: 0;
  margin: 0 auto;
  position: relative;

  svg {
    margin: 0 auto;
  }

  .MuiBadge-root {
    margin: 0 auto;
    vertical-align: top;
  }
`;

export default function Notify() {
  const match = useRouteMatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auth] = useReducerAuth();
  const interval = auth && auth.user ? auth.user.onlineTimeout || 30000 : 15000;
  const { notifyList: socketNotifyList } = useSocketUser();
  const [listNotify, setListNotify] = useState([]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [response] = useOnline({
    interval,
    url: '/sessions/online',
    payload: { url: `${apiURL}${match.path}` },
  });

  const removeBadge = useCallback((badgeId) => {
    handleClose();
    setListNotify((old) => old.filter((o) => o.id !== badgeId));
  }, []);

  const getListNotify = useCallback(() => {
    const onlineNotify = response.notification || [];
    setListNotify([...onlineNotify, ...socketNotifyList]);
  }, [response, socketNotifyList]);

  useEffect(() => {
    getListNotify();
  }, [getListNotify]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Label title={`${interval} `}>
      <Badge
        badgeContent={listNotify.filter((f) => !!f.badge).length}
        color="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <IoIosNotifications aria-describedby={id} onClick={handleClick} />
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {listNotify &&
          listNotify.map((notify) => {
            return (
              <ItemNotification
                key={`n-${Math.random()}`}
                title={notify.title}
                description={notify.description}
                onActionClick={removeBadge}
                action={notify.action}
              />
            );
          })}
        <ItemNotification title={`Vers\u00e3o: ${config.appVersion}`} />
      </Popover>
    </Label>
  );
}
