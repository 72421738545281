import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import TableContext from './TableContext';

function Td({ keyOfColumn, row }) {
  const { getColumns } = useContext(TableContext);

  const [result] = getColumns().filter((c) => {
    return !!(c.propData === keyOfColumn && c.Cell);
  });

  if (result) {
    const Comp = result.Cell;
    return (
      <td>
        <Comp row={row} />
      </td>
    );
  }
  return <td>{row[keyOfColumn] || ''}</td>;
}

Td.propTypes = {
  keyOfColumn: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export default memo(Td);
