import React, { useEffect } from 'react';
import { useReducerTheme } from '~/store/hooks/theme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import Global from './Global';

export default function GlobalStyle() {
  const [themeData, requestTheme] = useReducerTheme();
  // console.log('themeData', themeData);
  useEffect(() => {
    requestTheme();
  }, [requestTheme]);
  return <Global theme={themeData.data} />;
}
