/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Select from './select';

export default function CampoSelect(props) {
  const ref = useRef();
  const { name, campoClass, ...rest } = props;

  const classe = cx('campo', 'lbicon', campoClass, {
    disabled: !!rest.disabled,
  });

  return (
    <div className={classe} ref={ref}>
      <Select name={name} className="primary" {...rest} />
    </div>
  );
}

CampoSelect.propTypes = {
  name: PropTypes.string.isRequired,
  campoClass: PropTypes.string,
};

CampoSelect.defaultProps = {
  campoClass: null,
};
