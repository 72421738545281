import axios from 'axios';

import interceptorResponse, { onResponseError } from './interceptorResponse';
import interceptorRequest from './interceptorRequest';

import { apiURL as baseURL } from '~/config';

const Api = axios.create({ baseURL });

Api.interceptors.request.use(interceptorRequest);
Api.interceptors.response.use(interceptorResponse, onResponseError);

export default Api;

export { default as ApiAuthless } from './ApiAuthless';

/**
 * @function getAuthorization
 * @param {Object} payload
 */
export async function getAuthorization(payload) {
  const result = await Api.post(`/sessions/authorization`, payload);
  if (!result || !result.data) return false;
  return result.data;
}

export const axiosCancelSource = axios.CancelToken.source();
