import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const moving = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(80px);
  }

  100% {
    transform: rotate(0);
  }
`;

const WaitingContainer = styled.div`
  position: relative;
  padding: 4px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  height: ${({ h }) => `${h}px` || 'auto'};
`;

const WaitingLoader = styled.div`
  padding: 0;
  position: relative;
  margin: 0 auto;
  width: 92px;
  max-width: 100%;
  display: block;
  height: 12px;
  box-sizing: border-box;
`;

const WaitingAnime = styled.div`
  box-sizing: border-box;
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 12px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  animation: ${moving} 2s linear infinite;
`;

const P = styled.p`
  position: relative;
  margin: 0 auto;
  padding: 2px;
  font-size: 12px;
  text-align: center;
  max-width: 100%;
`;

function Waiting({ children, text, height }) {
  function renderText() {
    return text ? <P>{text}</P> : null;
  }

  return (
    <WaitingContainer h={height}>
      <div>
        {children || renderText()}
        <WaitingLoader>
          <WaitingAnime />
        </WaitingLoader>
      </div>
    </WaitingContainer>
  );
}

Waiting.defaultProps = {
  children: null,
  text: null,
  height: 0,
};
Waiting.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  height: PropTypes.number,
};

export default memo(Waiting);
