import React from 'react';
import PropTypes from 'prop-types';
import { ImageMessage } from 'avatardomus-components';

export default function NoSelected({ text, type }) {
  return (
    <div>
      <br />
      <ImageMessage type={type} text={text} />
    </div>
  );
}

NoSelected.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};

NoSelected.defaultProps = {
  text: null,
  type: 'Unknow',
};
