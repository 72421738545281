import React, { useState } from 'react';

// COMPONENTS
import { RollColumn, Column } from 'rollcolumn-react';

import { panelName } from '../makeData';
import { useIsMounted } from '~/services/customHooks';
import TableLPR from './TableLPR';
import FormLPR from './FormLPR';
import DeleteLPR from './DeleteLPR';

export default function PanelManagers() {
  const isMounted = useIsMounted();
  const [selectedColumn, setSelectedColumn] = useState(false);

  function handleColumnChange(_o, order) {
    if (order && isMounted.current) setSelectedColumn(order);
  }

  return (
    <RollColumn name={panelName} onColumnChange={handleColumnChange}>
      <Column key={1} order={1} checked>
        <TableLPR />
      </Column>
      <Column key={2} order={2}>
        {selectedColumn === 2 ? <FormLPR /> : null}
      </Column>
      <Column key={3} order={3}>
        {selectedColumn === 3 ? <DeleteLPR /> : null}
      </Column>
    </RollColumn>
  );
}
