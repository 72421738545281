import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { imgButton } from 'avatardomus-components';
import ToolItem from './ToolItem';

const ImagesButton = imgButton.requireList;

const getIcon = (key) => {
  return key in ImagesButton ? ImagesButton[key] : ImagesButton.unknow;
};

export default function ToolLink(props) {
  const linkRef = useRef();
  const { children, className, disabled, icon, value, ...rest } = props;
  const styles = { backgroundImage: `url(${getIcon(icon)})` };
  const path = disabled ? '' : value;

  const classBtn = classNames(
    'toolsbar-btn',
    'hover',
    {
      disabled: !!disabled,
    },
    className,
  );

  return (
    <ToolItem>
      <Link
        ref={linkRef}
        to={path}
        className={classBtn}
        disabled={disabled}
        style={styles}
        {...rest}
      >
        {children}
      </Link>
    </ToolItem>
  );
}

ToolLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
};

ToolLink.defaultProps = {
  children: ' ',
  className: null,
  disabled: false,
  hint: null,
  icon: 'unknow',
  value: null,
  // type: 'button',
};
