import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useParams, useLocation, Redirect, Link } from 'react-router-dom';
import { parse } from 'query-string';
import { CircleLoading } from 'avatardomus-components';
import { checkPassport } from '~/services/Api/makeData/session';
import { useIsMounted } from '~/services/customHooks';
import { useReducerAuth } from '~/store/hooks/auth';
import NoSelected from '~/components/NoSelected';

export default function PagePassport() {
  const [auth, { setAuth }] = useReducerAuth();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { passport: queryPassport } = parse(location.search);
  const { passport: paramPassport } = useParams();
  const [loading, setLoading] = useState(true);
  const [expired, setExpired] = useState(false);

  const passport = useMemo(() => {
    return paramPassport || queryPassport;
  }, [queryPassport, paramPassport]);

  const logged = useMemo(() => {
    return !!(auth.signed && auth.token);
  }, [auth]);

  const loginUser = useCallback(
    (userData) => {
      if (!logged)
        setAuth({
          token: userData.token,
          signed: !!userData.token,
          user: userData.data,
          expires: userData.expires,
        });
    },
    [logged, setAuth],
  );

  const checkLink = useCallback(async () => {
    if (passport && !logged) {
      const response = await checkPassport(passport);
      if (isMounted.current) {
        setLoading(false);
        if (response && response.success) loginUser(response);
        else setExpired(true);
      }
    }
  }, [passport, isMounted, loginUser, logged]);

  useEffect(() => {
    checkLink();
  }, [checkLink]);

  if (logged) {
    return <Redirect to="/sindico" />;
  }

  return (
    <>
      {expired ? (
        <div>
          <NoSelected type="Expires" text="Esse link expirou" />
          <p className="c">
            Por favor, <Link to="/login">clique aqui</Link> para fazer login
          </p>
        </div>
      ) : null}
      {loading ? <CircleLoading text="Verificando link..." /> : null}
    </>
  );
}
