import api from '~/services/Api';

/**
 * @typedef {Object} InviteGestor
 * @property {Number} gestorId
 *
 * @function sendInviteGestor
 * @param {InviteGestor} payload
 */
export async function sendInviteGestor(payload = {}) {
  const result = await api.post(`/gestores/invite`, payload);
  if (!result || !result.data) return false;
  return result.data;
}
