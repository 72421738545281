import React from 'react';
import { ListCards, CardItem, CardContent } from 'avatardomus-components';

import { ChartsProvider } from './ChartsContext';
import Events from './Events';
import WakeupAlert from './WakeupAlert';
import DevicesCount from './DevicesCount';

export default function Chats() {
  return (
    <ChartsProvider>
      <ListCards>
        <CardItem desk={25} tablet={50} mobile={100}>
          <CardContent>
            <Events />
          </CardContent>
        </CardItem>
        <CardItem desk={25} tablet={50} mobile={100}>
          <CardContent>
            <WakeupAlert />
          </CardContent>
        </CardItem>
        <CardItem desk={25} tablet={50} mobile={100}>
          <CardContent>
            <DevicesCount />
          </CardContent>
        </CardItem>
        <CardItem desk={25} tablet={50} mobile={50} hidden>
          <CardContent>DESPERTA PORTEIRO</CardContent>
        </CardItem>
      </ListCards>
    </ChartsProvider>
  );
}
