/* eslint-disable no-console */
import { name, version } from '../package.json';

const dev = !!(process.env.NODE_ENV === 'development');
const urlApi = process.env.REACT_APP_API_URL;
const devUrlApi = 'http://localhost:3003';

export const appName = process.env.REACT_APP_NAME || name;
export const appLabel = `Gestor Guaritas ${version}`;
export const apiURL = dev ? devUrlApi : urlApi;
export const homepageBase = dev ? '' : '';
export const publicUrl = dev
  ? 'localhost'
  : process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL || 'localhost';

// console.log('process.env', process.env);

const config = {
  appName,
  appLabel,
  apiURL,
  homepageBase,
  publicUrl,
  appVersion: version,
  storageName: `${appName}-${version}`,
};

// console.log('apiURL', apiURL);
// console.log('homepageBase', homepageBase);
export default config;
