/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TableContext from './TableContext';

import ImgQNd from './q-nd.svg';

export default function CheckTable(props) {
  const radioRef = useRef();
  const { registerRadio, radioCheck, radioChange } = useContext(TableContext);
  const { row, name, checked, disabled, value, onChange, onClick, onCheck } = props;

  const id = name ? `check-${name}-${row.id || value}` : `check-${row.id || value}`;

  function handleClick(e) {
    // console.log('RadioTable handleClick', e.target);
    const target = e.target || e.srcElement || false;
    if (e) onClick(e);
    if (target.checked) {
      radioCheck(e);
      onCheck(e);
    }
  }

  function handleChange(e) {
    if (e) onChange(e);
    radioChange(e);
  }

  useEffect(() => {
    const ref = radioRef.current || false;
    if (ref) {
      ref.checked = !!checked;
      ref.disabled = !!disabled;
      registerRadio(ref);
    }
  }, [checked, disabled, registerRadio]);

  const classDiv = cx('div-ctrl-linha', {
    checked: !!checked,
    disabled: !!disabled,
  });

  return (
    <div className={classDiv}>
      <img src={ImgQNd} className="ctrl-linha-bg" alt="select" />
      <input
        className="ctrl-linha"
        id={id}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
        ref={radioRef}
        type="checkbox"
        value={value}
      />
      <label htmlFor={id} />
    </div>
  );
}

CheckTable.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  row: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
};
CheckTable.defaultProps = {
  checked: false,
  disabled: false,
  name: 'noNamedTable',
  value: '',
  onClick: () => {},
  onChange: () => {},
  onCheck: () => {},
  row: { id: Math.random() },
};
