import React, { useState, useEffect, useCallback } from 'react';
import stateCallback from 'use-state-with-callback';

// components
import { CircleLoading } from 'avatardomus-components';
import { gotoColumn } from 'rollcolumn-react';
import TableDef from '~/components/TableDef';
import { clearInputsByName } from '~/components/TableDef/defaultOptions';
import { TableTools } from '~/pages/_common/TableTools';

import { addClass } from '~/helpers/domUtils';
import { useIsMounted } from '~/services/customHooks';
import { useReducerIds } from '~/store/hooks/ids';
import { useReducerTheme } from '~/store/hooks/theme';
import { listLPR, sendLprActivate } from '~/services/Api/makeData/lpr';
import { panelName } from '../../makeData';

import { columns, defaultOptions, tableName } from './columns';

export default function TableUsers() {
  const isMounted = useIsMounted();
  const [{ data: theme }] = useReducerTheme();
  const [lprId, setLprId] = useReducerIds('lpr');
  const [searching, setSearching] = useState(false);
  const [config, setConfig] = useState(defaultOptions);
  const [dataTable, setDataTable] = useState([]);
  const [jump, setJump] = useState(true);

  const [loading, setLoading] = stateCallback(false, (sinal) => {
    if (isMounted.current) setLprId(sinal ? 0 : lprId);
  });

  const handleFetchData = useCallback(
    async (dados) => {
      dados.page += 1;
      if (dados.total) {
        const maxpage = dados.total / dados.page;
        if (dados.page > maxpage) dados.page = Math.ceil(maxpage);
      }
      setLoading(true);
      clearInputsByName(tableName);
      const result = await listLPR(dados);
      if (isMounted.current) {
        if (result) {
          const { data, ...rest } = result;
          setDataTable(data);
          setConfig(rest);
          setJump(!!(rest.total && rest.total >= rest.size));
        }
        setLoading(false);
      }
    },
    [isMounted, setLoading],
  );

  const handleUpdate = useCallback(() => {
    const cfg = { ...config, page: (config.page -= 1) };
    if (cfg.page < 0) cfg.page = 0;
    handleFetchData(cfg);
    return false;
  }, [config, handleFetchData]);

  function handleToFind(searchText) {
    const filter = searchText && searchText.search ? { text: searchText.search || '' } : '';
    const cfg = { ...config, page: 0 };
    if (cfg.filter) delete cfg.filter;
    if (filter) cfg.filter = filter;
    handleFetchData(cfg);
    return false;
  }

  async function handleToogleChange(itemLine) {
    if (itemLine) {
      itemLine.disabled = true;
      const uid = parseInt(itemLine.value, 10) || 0;
      const result = await sendLprActivate(uid, !!itemLine.checked);
      itemLine.disabled = false;

      if (!!itemLine.checked !== !!result.actived) {
        itemLine.disabled = true;
        addClass(itemLine, 'error');
      }
    }
    return false;
  }

  function handleLineCheck(radio) {
    const id = parseInt(radio.value, 10) || 0;
    setLprId(id);
  }

  function handleCreateClick() {
    clearInputsByName(tableName);
    setLprId(-1);
    gotoColumn(panelName, 2, true);
  }
  const gotoEdit = () => gotoColumn(panelName, 2, true);
  const handleDelete = () => gotoColumn(panelName, 3, true);

  function sClick(e) {
    const eValue = parseInt(e.target.value, 10) || 0;
    const first = !!(eValue === 0);
    const newS = !searching;
    e.target.value = newS ? 2 : 1;
    if (!newS && !first) handleToFind();
    setSearching(newS);
  }

  const handlerTools = {
    searching,
    onSearch: handleToFind,
    searchButton: {
      onClick: sClick,
      activedColor: searching ? `${theme.secundary.color || theme.secundary || '#000'}` : '',
    },
    delButton: { disabled: !(lprId > 0), onClick: handleDelete },
    updateButton: { onClick: handleUpdate },
    editButton: { disabled: !(lprId > 0), onClick: gotoEdit },
    addButton: { onClick: handleCreateClick },
  };

  useEffect(() => {
    if (lprId === -2 && isMounted.current) handleUpdate();
  }, [isMounted, lprId, handleUpdate]);

  return (
    <>
      <TableTools {...handlerTools} title="Equipamentos" />
      <TableDef
        name={tableName}
        width={500}
        onCheck={handleLineCheck}
        onToogleChange={handleToogleChange}
        className="table-def"
        data={dataTable}
        columns={columns}
        pages={config.pages}
        defaultPageSize={defaultOptions.size}
        onFetchData={handleFetchData}
        manual
        showPageSizeOptions={false}
        defaultSorted={[{ id: 'id', desc: false }]}
        showPageJump={jump}
      />
      {loading ? <CircleLoading /> : null}
    </>
  );
}
