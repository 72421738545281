const bgHeader = require('./assets/bg-header.png');
const logo = require('./assets/domus.svg');
const avatarFoot = require('./assets/avatar-foot.svg');
const unknow = require('./assets/unknow.svg');

const listImages = {
  unknow,
  logo,
  bgHeader,
  avatarFoot,
};

export default listImages;
