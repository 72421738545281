/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import {
  CircleLoading,
  ImageMessage,
  FormContent,
  Grupo,
  Campo,
  CampoContent,
  ButtonCampo,
  CampoHidden,
} from 'avatardomus-components';

import { useReducerAuth } from '~/store/hooks/auth';
import { useIsMounted } from '~/services/customHooks';
import { sendRedefined } from '../../makeData';

function replaceUserData(data = {}) {
  const { user } = data;
  const newUser = {
    pwHasBeenReset: true,
    key: null,
  };
  return {
    ...user,
    ...newUser,
  };
}

export default function FormReset({ authorize, firstTime }) {
  const isMonted = useIsMounted();
  const [authData, { setAuth }] = useReducerAuth();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState({});

  const handleSubmit = useCallback(
    async (formData) => {
      setLoading(true);
      const result = await sendRedefined(formData);
      if (isMonted.current) {
        setLoading(false);
        if (result) setData(result);
      }
    },
    [isMonted],
  );

  const handleLogin = useCallback(() => {
    setAuth(replaceUserData({ ...authData }));
  }, [authData, setAuth]);

  if (redirect) return <Redirect to={redirect} />;
  const first = authData && authData.user ? !authData.user.pwHasBeenReset : !!firstTime;

  return (
    <div className="pad6">
      <p className="c">
        <strong>{`Redefini\u00e7\u00e3o de senha`}</strong>
        <br />
        {first
          ? `Verificamos que \u00e9 o seu primeiro login. Para sua seguran\u00e7a, por favor, cadastre uma nova senha de acesso`
          : `Por favor, informe sua nova senha.`}
      </p>
      {!data.success ? (
        <FormContent onSubmit={handleSubmit} initialData={authorize}>
          <br />
          <CampoHidden name="key" type="hidden" />
          <Campo
            type="password"
            name="password"
            lbicon="password"
            placeholder="senha"
            wchild="w50"
            autoComplete="new-password"
          />
          <Campo
            type="password"
            name="rpassword"
            lbicon="password"
            placeholder="confirme a senha"
            autoComplete="new-password"
            wchild="w50"
          />
          <hr />
          <Grupo fixed>
            {!first ? (
              <ButtonCampo
                type="button"
                wchild="w50"
                model="text"
                onClick={() => {
                  setRedirect('/login');
                }}
              >
                LOGIN
              </ButtonCampo>
            ) : (
              <CampoContent wchild="w50" />
            )}

            <ButtonCampo type="submit" wchild="w50" model="submit" disabled={loading}>
              {loading ? 'Salvando...' : 'GRAVAR'}
            </ButtonCampo>
          </Grupo>
        </FormContent>
      ) : (
        <div className="c">
          <ImageMessage type="Joia" text="Sua senha foi alterada com sucesso!" />
          <p className="c">
            {`Agora voc\u00ea pode fazer login`}
            <br />
            {/* <Link to="/login">{`p\u00e1gina de login`}</Link> */}
          </p>
          <FormContent>
            <ButtonCampo type="button" wchild="w50" model="text" onClick={handleLogin}>
              LOGIN
            </ButtonCampo>
          </FormContent>
        </div>
      )}
      {loading ? <CircleLoading /> : null}
    </div>
  );
}
