import { takeLatest, call, put, all } from 'redux-saga/effects';

import { authActions } from '~/store/actions/constants';
import { failureLogin, successLogin, successLogout } from '~/store/actions/auth';

import ApiAuthless from '~/services/Api/ApiAuthless';

export function* getAuth({ payload }) {
  try {
    const { email, password } = payload;
    const newAuth = yield call(ApiAuthless.post, '/sessions', {
      email,
      password,
    });

    const { token } = newAuth.data || null;

    if (!token) return yield put(failureLogin());

    ApiAuthless.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(successLogin(newAuth.data));

    return true;
  } catch (error) {
    return yield put(failureLogin());
  }
}

export function* outAuth({ payload }) {
  try {
    const { token } = payload;
    const newLogout = yield call(ApiAuthless.post, '/sessions/logout', {
      token,
    });

    const response = newLogout.data || null;

    if (!response) return yield put(failureLogin());

    yield put(successLogout(response));

    return true;
  } catch (error) {
    return yield put(failureLogin());
  }
}

export default all([
  takeLatest(authActions.requestLogin, getAuth),
  takeLatest(authActions.requestLogout, outAuth),
  // takeLatest(authActions.manualStorage, setAuth),
  // takeEvery(authActions.failureAuth, setLogout),
]);
