import produce from 'immer';
import { themeActions } from '~/store/actions/constants';
import { appName } from '~/config';
import defaultTheme from '~/components/GlobalStyle/initialState';

const defaultState = {
  loading: false,
  data: defaultTheme,
  error: false,
  name: appName,
};

export default function themeReducer(state = defaultState, action) {
  return produce(state, (draft) => {
    const { payload, type } = action;
    switch (type) {
      case themeActions.requestTheme:
        draft.loading = true;
        break;
      case themeActions.successTheme:
        draft.loading = false;
        draft.data = payload.data;
        draft.name = payload.data.name || payload.name || appName;
        draft.error = false;
        break;
      case themeActions.failureTheme:
        draft.loading = false;
        draft.error = true;
        break;
      default:
    }
  });
}
