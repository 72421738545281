import React from 'react';
import PropTypes from 'prop-types';
import { ToolsBar, ItemTitle, ItemButtons, ToolButton } from 'avatardomus-components';
import { CampoSearch, FormContent } from '~/components/FormDomus';
import { defaultProps } from './defaultProps';

export default function TableTools(props) {
  const {
    searchButton,
    delButton,
    editButton,
    addButton,
    updateButton,
    searching,
    onSearch,
    title,
  } = props;

  const buildTitle = () => {
    if (typeof title === 'function') return title();
    return title;
  };

  const searchProps = searchButton ? { ...defaultProps.searchButton, ...searchButton } : false;
  const delProps = delButton ? { ...defaultProps.delButton, ...delButton } : false;
  const editProps = editButton ? { ...defaultProps.editButton, ...editButton } : false;
  const addProps = addButton ? { ...defaultProps.addButton, ...addButton } : false;
  const updateProps = updateButton ? { ...defaultProps.updateButton, ...updateButton } : false;

  return (
    <>
      <ToolsBar line>
        <ItemTitle text={buildTitle()} />
        <ItemButtons>
          {searchButton && <ToolButton {...searchProps} />}
          {delButton && <ToolButton {...delProps} />}
          {updateButton && <ToolButton {...updateProps} />}
          {editButton && <ToolButton {...editProps} />}
          {addButton && <ToolButton {...addProps} />}
        </ItemButtons>
      </ToolsBar>
      {searching ? (
        <FormContent>
          <CampoSearch onSearch={onSearch} />
        </FormContent>
      ) : null}
    </>
  );
}

TableTools.defaultProps = {
  searchButton: null,
  delButton: null,
  updateButton: null,
  editButton: null,
  addButton: null,
  searching: false,
  onSearch: () => {},
  title: `sem t\u00edtulo`,
};

TableTools.propTypes = {
  searchButton: PropTypes.object,
  delButton: PropTypes.object,
  updateButton: PropTypes.object,
  editButton: PropTypes.object,
  addButton: PropTypes.object,
  searching: PropTypes.bool,
  onSearch: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
