import React from 'react';
import PropTypes from 'prop-types';
import { BoxCenter } from 'avatardomus-components';

export default function AppCenter(props) {
  const { children, ...rest } = props;
  return <BoxCenter {...rest}>{children}</BoxCenter>;
}

AppCenter.propTypes = {
  children: PropTypes.any,
};

AppCenter.defaultProps = {
  children: null,
};
