import { imgIcon } from 'avatardomus-components';
import AppCenter from '~/pages/_layouts/AppCenter';
import Home from '~/pages/Home';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import Forgot from '~/pages/Forgot';
import Resetpass from '~/pages/Forgot/Resetpass';
import Invite from '~/pages/Invite';
import PagePassport from '~/pages/Passport';
import About from '~/pages/About';
import NoFound from '~/pages/NoFound';
// import NoPage from '~/pages/NoPage';

import { prepareRouteList } from '~/routes/routeHelpers';

const publicRouteList = [
  {
    path: '/',
    name: 'Home',
    icon: imgIcon.requireList.dashboard,
    component: Home,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: imgIcon.requireList.dashboard,
    component: Login,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: imgIcon.requireList.dashboard,
    component: Logout,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/esqueci',
    name: 'Recuperar senha',
    icon: imgIcon.requireList.dashboard,
    component: Forgot,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/resetpassword',
    name: 'Redefinir senha',
    icon: imgIcon.requireList.dashboard,
    component: Resetpass,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/sobre',
    name: 'Sobre',
    icon: imgIcon.requireList.dashboard,
    component: About,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/invite',
    name: 'Convite',
    icon: imgIcon.requireList.dashboard,
    component: Invite,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/passport',
    name: 'Passport',
    icon: imgIcon.requireList.dashboard,
    component: PagePassport,
    layout: AppCenter,
    exact: true,
  },
  {
    path: '/passport/:passport',
    name: 'Passport',
    icon: imgIcon.requireList.dashboard,
    component: PagePassport,
    layout: AppCenter,
    exact: true,
  },
  // lasted
  {
    path: '*',
    component: NoFound,
    layout: AppCenter,
  },
];

export default prepareRouteList(publicRouteList, false);
