import logo from './assets/domus.svg';
import bgHeader from './assets/bg-header.png';
import avatarFoot from './assets/avatar-foot.svg';
import unknow from './assets/unknow.svg';

export const Imageslayout = {
  unknow,
  logo,
  bgHeader,
  avatarFoot,
};

export default Imageslayout;
