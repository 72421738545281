import React from 'react';
import PropTypes from 'prop-types';

// CONPONENTS
import '~/components/_layout/PrintReports/print-report.css';
import { PrintReportContainer } from '~/components/_layout/PrintReports';

export default function PrintReportLayout({ children }) {
  return <PrintReportContainer>{children}</PrintReportContainer>;
}

PrintReportLayout.propTypes = { children: PropTypes.any };
PrintReportLayout.defaultProps = { children: null };
