import React, { useEffect, useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { ToolsBar, ItemTitle, CircleLoading } from 'avatardomus-components';
import { useIsMounted } from '~/services/customHooks';
import { useReducerIds } from '~/store/hooks/ids';
import { getLPR } from '~/services/Api/makeData/lpr';
import NoSelected from '../../NoSelected';

import PlateDetails from './Details';

function PlateLPR({ plateData }) {
  const isMounted = useIsMounted();
  const [lprId] = useReducerIds('lpr');
  const [lprData, setLprData] = useState({});
  const [loading, setLoading] = useState(false);

  const { imageFragmentFile, license, lastInteraction, ipaddr, confidence } = plateData;

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getLPR(lprId);
    if (isMounted.current) {
      if (result) setLprData(result);
      setLoading(false);
    }
  }, [isMounted, lprId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function buildTitle(t) {
    return t ? `LPR: ${t}` : '--';
  }

  if (!lprId || (lprId && lprId <= 0)) {
    return <NoSelected type="Click" text="Selecione um equipamento LPR" />;
  }

  return (
    <>
      <ToolsBar line>
        <ItemTitle text={buildTitle(lprData.label || '')} />
      </ToolsBar>
      <PlateDetails
        online={!!lprData.online}
        license={license}
        lastInteraction={lastInteraction}
        ipaddr={ipaddr}
        confidence={confidence}
        imageFragmentFile={imageFragmentFile}
      />
      {loading ? <CircleLoading /> : null}
    </>
  );
}

PlateLPR.defaultProps = {
  plateData: {},
};
PlateLPR.propTypes = {
  plateData: PropTypes.object,
};

export default memo(PlateLPR);
